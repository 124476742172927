import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { HttpServiceHelper } from '../interfaces/HttpService';
import { HttpRestService } from '../services/HttpRest.service';
import { HttpRestMockService } from '../services/HttpRest.service.mock';

import { environment } from './../../environments/environment';

export const HTTP_HELPER = new InjectionToken<HttpServiceHelper>('http.helper');

const httpHelperFactory = (httpClient: HttpClient) => {
  if (environment.useMockData) {
    return new HttpRestMockService(httpClient);
  }
  return new HttpRestService(httpClient);
};

export const HttpHelperProvider = {
  provide: HTTP_HELPER,
  useFactory: httpHelperFactory,
  deps: [HttpClient],
};
