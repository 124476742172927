import { Component, Input } from '@angular/core';
import { ROUTE_IDS } from 'src/app/config';
import { getEventName } from 'src/app/helpers/EventHelpers';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { RoutingService } from 'src/app/services/Routing.service';

@Component({
  selector: 'iko-va-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent {
  @Input() event: msevtmgt_event | undefined;

  constructor(private routingService: RoutingService) {}

  navigateToParent(e: Event) {
    e.preventDefault();
    window.history.pushState({}, '', '/veranstaltungen');
    this.routingService.dispatchRouteChangeEvent({
      page: ROUTE_IDS.HOME,
    });
  }

  getTitle(): string{
    return getEventName(this.event);
  }
}
