<table class="sd-2-11-0-table" style="width: 100%;">
  <colgroup>
    <col span="1" style="width: 10%" />
    <col span="1" style="width: 90%" />
    <col span="1"  />
  </colgroup>
  <tbody>
    <tr
      *ngFor="let child of childEvents; index as i; first as isFirst"
    >
      <td class="sd-2-11-0-table-cell" style="padding-left: 0; text-align: center;">
        <div class="sd-2-11-0-paragraph" style="font-weight: 350;" [innerHTML]="getEventDate(child)">
          
        </div>
      </td>
      <td class="sd-2-11-0-table-cell eventmsg-desktop">
        <iko-va-roadshow-registration-addr [child]="child"></iko-va-roadshow-registration-addr>
      </td>
      <td class="sd-2-11-0-table-cell" style="padding-right: 0;">
        <div class="eventmsg-mobile" >
          <iko-va-roadshow-registration-addr [child]="child" class=""></iko-va-roadshow-registration-addr>
        </div>
        <div class="grid">
          <sd-2-11-0-button
          title=" {{isRegistrationDateInPast(child.msevtmgt_eventid)? 'Onlineanmeldung nicht mehr möglich':'Anmelden'}}"
            [disabled] = "isParticipantRegisteredAtAnyChildEvent() || isInPast(child.msevtmgt_eventid) || isRegistrationDateInPast(child.msevtmgt_eventid)"
            variant="primary"
            size="lg"
            type="button"
            *ngIf="!isParticipantRegistered(child.msevtmgt_eventid)"
            (click)="doRegisterEvent(child.msevtmgt_eventid)"
            >Jetzt anmelden</sd-2-11-0-button
          >
          <div id="part-base" [ngClass]="isInPast(child.msevtmgt_eventid)?'':'red-border'">
            <sd-2-11-0-button
              title="Stornieren"
              [disabled] = "isInPast(child.msevtmgt_eventid)"
              variant="secondary"
              size="lg"
              type="button"
              *ngIf="isParticipantRegistered(child.msevtmgt_eventid)"
              (click)="doCancelRegistrationEvent(child.msevtmgt_eventid)"
              ><div [ngClass]="isInPast(child.msevtmgt_eventid)?'':'red'">Teilnahme stornieren</div></sd-2-11-0-button
            >
          </div>
          <sd-2-11-0-button
            title="Event im Kalender speichern"
            variant="secondary"
            size="lg"
            type="button"
            (click)="downloadICSFile(child.msevtmgt_eventid)"
            >Im Kalender speichern</sd-2-11-0-button
          >
        </div>
      </td>
    </tr>
  </tbody>
</table>
