<sd-4-0-0-teaser variant="neutral-100" breakpoint="9999" class="teaser" inset>
  <div slot="media" class="media">
    <img
      class="image"
      [src]="getImage()"
      [alt]="getFormattedEventName() || 'Event Teaser'"
    />

    <div class="locations">
      <span
        class="sd-1-0-1-chip sd-1-0-1-chip--white"
        *ngIf="this.locations.length > 0"
      >
        {{ this.locations[0] }}
      </span>

      <span
        class="sd-1-0-1-chip sd-1-0-1-chip--white"
        *ngIf="this.locations.length > 1"
      >
        +{{ this.locations.length - 1 }} weitere
      </span>

      <!--136470001 equals Webconference-->
      <span
        class="sd-1-0-1-chip sd-1-0-1-chip--white"
        *ngIf="
          participantStatus?.crmikpk_child_event_id?.msevtmgt_eventtype ===
          136470001
        "
      >
        Livestream
      </span>
    </div>
  </div>
  <div slot="meta" class="meta">
    <time class="sd-1-0-1-meta timestamp">{{ getFormattedDateSpan() }}</time>
  </div>
  <h2
    slot="headline"
    class="sd-1-0-1-headline sd-1-0-1-headline--size-lg event-name"
  >
    {{ getFormattedEventName() }}
  </h2>

  <div class="main">
    <p class="description">{{ getTrimmedDescription() }}</p>
    <sd-4-0-0-button
      (click)="onNavigate()"
      class="cta-btn"
      variant="secondary"
      size="lg"
      type="button"
    >
      Mehr erfahren
    </sd-4-0-0-button>
  </div>
</sd-4-0-0-teaser>
