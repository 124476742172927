import { Component } from '@angular/core';

/**
 * Notification/Alert component
 * @important It is a placeholder component until sd-notification component is released from sd-library
 * @example
 * <iko-va-sd-notification>Your custom message!</iko-va-sd-notification>
 */
@Component({
  selector: 'iko-va-sd-notification',
  templateUrl: './sd-notification.component.html',
  styleUrls: ['./sd-notification.component.css'],
})
export class SdNotificationComponent {}
