<div class="backdrop" (click)="close()"></div>

<div class="modal">
  <sd-2-11-0-button title="Schließen" variant="tertiary" type="button" class="close-btn" (click)="close()">
    <sd-2-11-0-icon name="system/close" library="default" color="primary" class="close-icon"></sd-2-11-0-icon>
  </sd-2-11-0-button>

  <div class="sd-2-11-0-headline sd-2-11-0-headline--size-xl">
    Anmeldung erfolgreich!
  </div>
  <div class="sd-2-11-0-paragraph sd-2-11-0-paragraph--size-default">
    Sie erhalten in Kürze eine E-Mail mit weiteren Informationen zu dieser
    Veranstaltung.
  </div>

  <div class="media-slot">
    <div class="media-image">
      <img class="image-margin" [attr.src]="getBannerImageURL()">
    </div>
    <div class="media-content">
      <h2>
        {{ getTtile() }}
      </h2>

      <table>
        <tr>
          <td>
            <div class="sd-2-11-0-paragraph" style="
                text-align: center;
                vertical-align: middle;
                margin-right: 30px;
                margin-bottom: 30px;
              ">
              <strong>
                {{ event?.msevtmgt_eventstartdate | date : "MMM" : "de" }}
                <br />
                {{ event?.msevtmgt_eventstartdate | date : "dd" }}
              </strong>
            </div>
          </td>
          <td>
            <div class="sd-2-11-0-paragraph sd-2-11-0-paragraph--size-sm" style="
                vertical-align: middle;
                margin-right: 30px;
                margin-bottom: 30px;
              ">
              {{ getEventTime()}}
              <br />
              <strong>{{ getTtile() }}</strong>
              <br />
              {{ event?.msevtmgt_building?.msevtmgt_addressline1 }} |
              {{ event?.msevtmgt_building?.msevtmgt_city }}
            </div>
          </td>
        </tr>
      </table>

      <sd-2-11-0-button style="margin-bottom: 32px" title variant="secondary" size="lg" type="button"
        (click)="downloadICSFile()" *ngIf="event && event.crmikpk_kalendereintrageinblenden">Im Kalender
        speichern</sd-2-11-0-button>
    </div>
  </div>
</div>