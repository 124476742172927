import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, concatMap, Observable,of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpServiceHelper } from '../interfaces/HttpService';
import { UserServiceHelper } from '../interfaces/UserService';
import { contact } from '../models/contact';
import { HTTP_HELPER } from '../providers/http.helper.provider';
import { queries } from '../queries/';
import { DynamicsAPIResponse } from '../types/DynamicsAPIResponse';

@Injectable()
export class UserService implements UserServiceHelper {
  constructor(@Inject(HTTP_HELPER) private http: HttpServiceHelper) {}

  public getUser(
    token: string | undefined
  ): Observable<contact[] | null> {
    // If moock data is allowed replace token with mock token
    if (environment.useMockAuthService) token = 'I am a token';

    if (!token) return of<contact[] | null>(null);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      }),
    };

    const url = `${environment.crm.apiEndpoint}/${queries.contactsEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}`;

    return this.http
      .get<DynamicsAPIResponse<contact>>(url, httpOptions)
      .pipe(
        concatMap((res) => {
        if (!res) return of(null);
        return of(res.value);
      }),
      catchError(err=>{console.error(err); return of(null);})
      );
  }
}
