<div class="backdrop" (click)="close()"></div>

<div class="modal">
  <sd-2-11-0-button
    title="Schließen"
    variant="tertiary"
    type="button"
    class="close-btn"
    (click)="close()"
  >
    <sd-2-11-0-icon
      name="system/close"
      library="default"
      color="primary"
      class="close-icon"
    ></sd-2-11-0-icon>
  </sd-2-11-0-button>

  <h3 class="sd-2-11-0-headline sd-2-11-0-headline--size-3xl heading">
    Zur Veranstaltung anmelden?
  </h3>

  <div class="sd-2-11-0-paragraph sd-2-11-0-paragraph--size-default">
    Möchten Sie sich für die Veranstaltung
    <strong>{{ signUpToEvent?.event_description }}</strong> anmelden?
  </div>

  <table class="table">
    <tr>
      <td>
        <sd-2-11-0-input
          #elem_salutation
          [value]="signUpToEvent?.registration?.salutation"
          type="text"
          size="lg"
          label="Anrede"
          disabled="true"
        >
        </sd-2-11-0-input>
      </td>
      <td>
        <sd-2-11-0-input
          #elem_title
          [value]="signUpToEvent?.registration?.title"
          type="text"
          size="lg"
          label="Titel"
          disabled="true"
        >
        </sd-2-11-0-input>
      </td>
    </tr>
  </table>

  <sd-2-11-0-input
    #elem_first_name
    [value]="signUpToEvent?.registration?.first_name"
    type="text"
    size="lg"
    label="Vorname"
    disabled="true"
  >
  </sd-2-11-0-input>

  <sd-2-11-0-input
    #elem_last_name
    [value]="signUpToEvent?.registration?.last_name"
    type="text"
    size="lg"
    label="Nachname"
    disabled="true"
  >
  </sd-2-11-0-input>

  <iko-va-registration-custom-field
    style="width: 100%"
    #custom_field_component
    *ngFor="let regField of registrationFields"
    [regField]="regField"
    >>
  </iko-va-registration-custom-field>

  <sd-2-11-0-checkbox-group size="lg" orientation="vertical">
    <sd-2-11-0-checkbox
      #elem_agb_prim
      [checked]="signUpToEvent?.registration?.terms_of_use_prim"
      >Ich habe die <a href="localhost">Datenschutzhinweise</a> gelesen und
      akzeptiert.*</sd-2-11-0-checkbox
    >
    <sd-2-11-0-checkbox
      #elem_agb_ex
      [checked]="signUpToEvent?.registration?.terms_of_use_ex"
    >
      Ich bestätige, dass meine Teilnahme an dieser Veranstaltung im Einklang<br />
      mit den gesetzlichen Vorgaben und den internen Regelungen meines<br />
      Hauses steht, dass etwaig erforderliche Genehmigungen zuständiger<br />
      Stellen aus meinem Haus vorliegen und die Annahme daher zulässig ist.*
    </sd-2-11-0-checkbox>
  </sd-2-11-0-checkbox-group>

  <table class="table" *ngIf="validation_errors != null">
    <tr>
      <td>
        <sd-2-11-0-notification
          open=""
          variant="error"
          toast-stack="top-right"
          duration="Infinity"
        >
          <div>
            <ng-container *ngFor="let msg of validation_errors"
              >{{ msg }}<br
            /></ng-container>
          </div>
        </sd-2-11-0-notification>
      </td>
    </tr>
  </table>

  <div class="button-row">
    <sd-2-11-0-button
      title="Abbrechen"
      variant="secondary"
      size="lg"
      type="button"
      (click)="this.close()"
      [disabled]="this.in_progress === true"
    >
    Abbrechen
    </sd-2-11-0-button>

    <sd-2-11-0-button
      variant="primary"
      type="submit"
      [loading]="in_progress"
      (click)="onSubmit()"
    >
      Anmeldung absenden
    </sd-2-11-0-button>
  </div>
</div>
