import { Component, HostBinding, HostListener, Input } from '@angular/core';
import {
  buildEventDetailURL,
  getFormattedDateSpan,
  getFormattedEventName,
  getImage,
  getLocations,
  getStatus,
  getStatusClass,
  getTrimmedDescription,
  isRoadshow,
} from 'src/app/helpers/EventHelpers';
import { crmikpk_participantstatus } from 'src/app/models/crmikpk_participantstatus';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { RoutingService } from 'src/app/services/Routing.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { TagVariants } from 'src/app/types/Enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'iko-va-event-widget-card',
  templateUrl: './event-widget-card.component.html',
  styleUrls: ['./event-widget-card.component.css'],
})
export class EventWidgetCardComponent {
  @Input({ required: true }) participantStatus?: crmikpk_participantstatus;
  @Input() cardVariant?: string;

  status?: string;
  eventURL?: string;
  locations: string[] = [];
  isRoadshow: boolean = false;
  cityTagVariant: TagVariants = TagVariants.LightBlue;
  navigationActive: boolean = false;
  constructor(
    private trackingService: TrackingService,
    private routingService: RoutingService
  ) {}

  @HostBinding() tabindex = 0;
  @HostListener('click', ['$event']) onClick(e: PointerEvent) {
    e.stopPropagation();

    if (!this.participantStatus) return;

    this.onNavigate();
  }

  onNavigate() {
    if (!this.navigationActive) {
      this.navigationActive = true;
      this.trackingService.track({
        eventInfo: {
          eventType: 'Action',
          eventName: `Clicked teaser '${this.getFormattedEventName()}'`,
        },
        category: {
          area: 'Content',
        },
        attributes: {
          elementLabel: this.getFormattedEventName(),
          elementType: 'Teaser',
          customECommerceParameter: {
            48: 'sd-4-0-0-teaser',
            49: 'sd-4-0-0',
            50: environment.app.NAME,
          },
          target: this.eventURL,
        },
      });

      if (this.eventURL) {
        const path = this.eventURL.replace(window.location.origin, '');

        this.routingService.navigateWithInShell(path);
        //window.location.href = this.eventURL;
      } else {
        console.warn('No event url given to navigate to!');
        this.navigationActive = false;
      }
    } else {
      this.navigationActive = false;
    }
  }

  ngOnInit() {
    if (this.participantStatus && this.participantStatus.crmikpk_eventid) {
      this.isRoadshow = isRoadshow(this.participantStatus.crmikpk_eventid);
    }

    this.status = this.participantStatus && getStatus(this.participantStatus);
    this.locations = this.participantStatus
      ? getLocations(this.participantStatus, this.isRoadshow)
      : [];
    this.eventURL =
      this.participantStatus && this.getEventURL(this.participantStatus);
  }

  getEventURL(participantStatus: crmikpk_participantstatus) {
    const event =
      participantStatus.crmikpk_child_event_id ||
      participantStatus.crmikpk_eventid;

    if (event) {
      const isParent =
        !participantStatus.crmikpk_child_event_id ||
        participantStatus.crmikpk_eventid?.msevtmgt_eventid ===
          participantStatus.crmikpk_child_event_id.msevtmgt_eventid;

      const eventId = event.msevtmgt_eventid;
      let childEventId: string | undefined;
      if (
        participantStatus.crmikpk_eventid &&
        !participantStatus.crmikpk_eventid.crmikpk_isroadshowbool
      ) {
        childEventId =
          participantStatus.crmikpk_child_event_id?.msevtmgt_eventid;
        if (isParent) {
          childEventId = this.getEventId(participantStatus.crmikpk_eventid);
        }
      }

      const url = buildEventDetailURL(
        eventId,
        window.location.origin,
        isParent,
        childEventId
      );

      return url.toString();
    }
    return '';
  }

  getFormattedEventName() {
    return getFormattedEventName(this.participantStatus!);
  }

  getFormattedDateSpan() {
    return getFormattedDateSpan(this.participantStatus!);
  }

  getImage() {
    return getImage(this.participantStatus!);
  }

  getStatusClass() {
    return getStatusClass(this.participantStatus!);
  }

  getTrimmedDescription() {
    return getTrimmedDescription(this.participantStatus!);
  }

  getEventId(event: msevtmgt_event): string {
    if (
      1 === event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid?.length
    ) {
      return event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid[0]
        .msevtmgt_eventid;
    }
    return event.msevtmgt_eventid;
  }
}
