<div class="iko-va-errorcontainer">
    <sd-2-11-0-icon class="mb-3 iko-va-icon" name="union-investment/content/calendar"
        library="default"></sd-2-11-0-icon>
        <h2 class="sd-2-11-0-headline sd-2-11-0-headline--size-3xl iko-va-errorheadline mb-4">
            {{ errorName }}
          </h2>
          <p :class="`sd-2-11-0-paragraph mb-8`">{{ errorMessage }}</p>
          <sd-2-11-0-button *ngIf="buttonText && buttonaction"
          title="{{buttonText}}"
          variant="secondary"
          type="button"
          (click)="this.buttonaction($event)"
          >{{buttonText}}</sd-2-11-0-button
        >
</div>