<div class="icon-wrapper">
  <sd-2-11-0-icon
    class="icon"
    name="system/warning"
    library="default"
    color="currentColor"
  ></sd-2-11-0-icon>
</div>
<div class="message-wrapper">
  <p class="message sd-2-11-0-paragraph"><ng-content></ng-content></p>
</div>
