export interface msevtmgt_eventcustomregistrationfield {
  msevtmgt_eventcustomregistrationfieldid: string;
  msevtmgt_customregistrationfield: msevtmgt_customregistrationfield;
  msevtmgt_order: number;
}

export interface msevtmgt_customregistrationfield {
  crmikpk_srccreatedby: string;
  crmikpk_srccreatedon: Date;
  crmikpk_srccreatedsys: string;
  crmikpk_srcmodifiedby: string;
  crmikpk_srcmodifiedon: Date;
  crmikpk_srcmodifiedsys: string;
  crmikpk_standardbool: boolean;
  crmikpk_standardboolname: boolean;
  crmikpk_statuscodechange: Date;
  crmikpk_title: string;
  crmikpk_uukey: string;
  msevtmgt_choices: string;
  msevtmgt_customregistrationfieldid: string;
  msevtmgt_isrequired: boolean;
  msevtmgt_isrequiredname: boolean;
  msevtmgt_text: string;
  msevtmgt_type: msevtmgt_typeEnum;
  msevtmgt_typename: msevtmgt_typeEnum;

  createdby?: string;
  createdbyname?: string;
  createdbyyominame?: string;
  createdon?: Date;
  createdonbehalfby?: string;
  createdonbehalfbyname?: string;
  createdonbehalfbyyominame?: string;
  importsequencenumber?: number;
  modifiedby?: string;
  modifiedbyname?: string;
  modifiedbyyominame?: string;
  modifiedon?: Date;
  modifiedonbehalfby?: string;
  modifiedonbehalfbyname?: string;
  modifiedonbehalfbyyominame?: string;
  overriddencreatedon?: Date;
  ownerid?: string;
  owneridname?: string;
  owneridtype?: string;
  owneridyominame?: string;
  owningbusinessunit?: string;
  owningbusinessunitname?: string;
  owningteam?: string;
  owninguser?: string;
  statecode?: statecodeEnum;
  statecodename?: statecodeEnum;
  statuscode?: statuscodeEnum;
  statuscodename?: statuscodeEnum;
  timezoneruleversionnumber?: number;
  utcconversiontimezonecode?: number;
  versionnumber?: number;
}
export enum msevtmgt_typeEnum {
  Simpletext = 100000000,
  Booleanyes_no = 100000001,
  Singlechoice = 100000003,
  Multiplechoice = 100000002,
}
export enum statecodeEnum {}
export enum statuscodeEnum {}
