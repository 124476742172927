import { Injectable, OnDestroy } from '@angular/core';
import { RouterService } from '@microfrontend-toolkit/router-service';
import { ROUTER_SERVICE_KEY } from '@microfrontend-toolkit/router-service/constants';

import { ServiceProvider } from '../helpers/ServiceProvider';
import RouteChangeEvent from '../types/RouteChangeEvent';

export type CallbackFunction = (data: CustomEvent<RouteChangeEvent>) => void;
const LISTENER_NAME = 'onVARouteChange';

@Injectable()
export class RoutingService extends ServiceProvider implements OnDestroy {
  private routeRegistered: boolean = false;
  private registeredCallback?: CallbackFunction;
  private routerService?: RouterService;

  constructor() {
    super();
    this.routerService =
      this._serviceProvider?.getService<RouterService>(ROUTER_SERVICE_KEY);
  }

  registerRouteChangeListener(callback: CallbackFunction): boolean {
    if (this.routeRegistered) {
      console.warn(
        'There is already one listener attached. Listener Limit maxed out'
      );
      return true;
    }
    window.addEventListener(LISTENER_NAME, callback as EventListener);
    window.addEventListener('popstate', callback as EventListener);
    this.registeredCallback = callback;
    this.routeRegistered = true;
    return true;
  }

  unregisterRouteChangeListener(callback?: CallbackFunction): void {
    if (!callback) callback = this.registeredCallback;

    window.removeEventListener(LISTENER_NAME, callback as EventListener);
    window.removeEventListener('popstate', callback as EventListener);

    this.routeRegistered = false;
    this.registeredCallback = undefined;
  }

  dispatchRouteChangeEvent(data: RouteChangeEvent) {
    window.dispatchEvent(
      new CustomEvent(LISTENER_NAME, {
        detail: data,
      })
    );
  }

  /**
   * Navigates the user within the shell without refresh the page
   * @param path - path where the shell should navigate the user
   */
  navigateWithInShell(path: string): void {
    if (!this.routerService) {
      console.error(
        'Cannot navigate within shell without refresh because routerService can not be acquired from shell. Fallbacking to browser default'
      );
      window.location.href = path;
    } else {
      this.routerService.navigate(path);
    }
  }

  ngOnDestroy(): void {
    if (this.registeredCallback) {
      this.unregisterRouteChangeListener(this.registeredCallback);
    }
  }
}
