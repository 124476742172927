import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  defaultFilters,
  sortEventsByFilter,
} from 'src/app/helpers/EventHelpers';
import { crmikpk_participantstatus } from 'src/app/models/crmikpk_participantstatus';
import { CacheService } from 'src/app/services/Cache.service';
import { EventService } from 'src/app/services/Event.service';
import { RoutingService } from 'src/app/services/Routing.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

/**
 * Max events to be displayed. Keep in mind that on smaller devices not all events (MAX_EVENTS) will be dispalyed
 */
const MAX_EVENTS = 3;

@Component({
  selector: 'va-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: [
    './events-widget.component.css',
    './../../../../node_modules/@solid-design-system/components/dist/versioned-styles/solid-styles.css',
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [EventService],
})
export class EventsWidgetComponent implements OnInit {
  status: crmikpk_participantstatus[] = [];
  loading = true;

  constructor(
    private eventService: EventService,
    private cacheService: CacheService,
    private trackingService: TrackingService,
    private routingService: RoutingService
  ) {}

  ngOnInit() {
    this.trackingService.init();
    this.loadEvents();
  }

  async loadEvents() {
    await this.cacheService.init();

    const cachedEvents = this.cacheService.getEvents();
    if (cachedEvents) {
      const filteredEvents = sortEventsByFilter(
        defaultFilters[0].filterEvents(cachedEvents),
        defaultFilters[0]
      ).slice(0, MAX_EVENTS);
      this.status = filteredEvents;
      this.loading = false;
    } else {
      this.eventService.getPublishedEventsByContactID(false).subscribe({
        next: (data) => {
          if (data) {
            this.cacheService.saveEvents(data);
            const filteredEvents = sortEventsByFilter(
              defaultFilters[0].filterEvents(data),
              defaultFilters[0]
            ).slice(0, MAX_EVENTS);
            this.status = filteredEvents;
          }
        },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  onShowAllEventsClick(event: MouseEvent) {
    event.preventDefault();
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Clicked "Alle Veranstaltungen anzeigen"`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementLabel: 'Alle Veranstaltungen anzeigen',
        elementType: 'Link',
        virtualTarget: new URL(
          '/veranstaltungen',
          window.location.origin
        ).toString(),
        customECommerceParameter: {
          48: 'sd-2-11-0-link',
          49: 'sd-2-11-0',
          50: environment.app.NAME,
        },
      },
    });

    this.routingService.navigateWithInShell('/veranstaltungen');
  }
}
