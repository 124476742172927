<div *ngIf="this.speakers && this.speakers.length > 0">
  <p class="sd-2-11-0-headline sd-2-11-0-headline--size-3xl">Referenten</p>
  <sd-2-11-0-carousel
    class="carousel"
    variant="dot"
    slides-per-page="4"
    slides-per-move="4"
  >
    <sd-2-11-0-carousel-item
      class="carousel-item"
      *ngFor="let speaker of this.speakers"
    >
      <sd-2-11-0-teaser class="teaser" breakpoint="9999">
        <img slot="media" class="image" [src]="getSpeakerImage(speaker)" />
        <h2
          slot="meta"
          class="sd-2-11-0-headline sd-2-11-0-headline--size-lg wrap-one-line"
        >
        {{ speaker?.msevtmgt_title }} {{ speaker.msevtmgt_name }}
        </h2>
        <div slot="headline">
          <h3 class="job sd-2-11-0-paragraph">
            {{ getMeta(speaker) }}
          </h3>
        </div>
        <div>
          <iko-va-read-more-link [click]="readMore(speaker)" [id]="'speaker_'+speaker?.msevtmgt_speakerid">
            <p class="sd-2-11-0-paragraph truncate-6">
              {{ speaker.msevtmgt_about }}
            </p>
          </iko-va-read-more-link>
          <iko-va-social-media-links [speaker]="speaker"></iko-va-social-media-links>
        </div>
      </sd-2-11-0-teaser>
    </sd-2-11-0-carousel-item>
  </sd-2-11-0-carousel>
  <iko-va-speaker-modal [(speaker)]="this.selectedSpeaker"></iko-va-speaker-modal>
</div>
