import { Component, Input } from '@angular/core';

type onClickFunction = (e?: Event) => void;

@Component({
  selector: 'iko-va-read-more-link',
  templateUrl: './read-more-link.component.html',
  styleUrls: ['./read-more-link.component.css'],
})
export class ReadMoreLinkComponent {
  @Input() click?: onClickFunction;

  readMore(e: Event) {
    e.preventDefault();
    this.click && this.click(e);
  }
}
