import { Injectable } from '@angular/core';
import type { ServiceProvider as ServiceProviderType } from '@union-investment-b2b-portal/service-provider';
import { ServiceProviderFactory } from '@union-investment-b2b-portal/service-provider';
import { environment } from 'src/environments/environment';

type ServiceProviderStatus = 'uninitialized' | 'initialized' | 'error';

@Injectable({
  providedIn: 'root',
})
export class ServiceProvider {
  _serviceProvider!: ServiceProviderType;
  serviceProviderStatus: ServiceProviderStatus = 'uninitialized';

  constructor() {
    const parentServiceProvider = !environment.test
      ? ServiceProviderFactory.getServiceProvider({
          mountOptions: { elementID: 'root' },
        })
      : null;

    if (!parentServiceProvider) {
      if (!environment.test)
        console.warn(
          'No service provider found! Trying to create an instance!'
        );
      this.createCustomInstance();
    } else {
      this._serviceProvider = parentServiceProvider;
      this.serviceProviderStatus = 'initialized';
    }
  }

  /**
   * Tries to create a custom instance of service provider
   */
  private createCustomInstance() {
    const DOMElement = document.querySelector('va-events')! as HTMLElement;

    const tryCreateServiceProvider =
      ServiceProviderFactory.createServiceProvider({
        mountOptions: { element: DOMElement },
      });
    if (tryCreateServiceProvider) {
      this._serviceProvider = tryCreateServiceProvider;
      this.serviceProviderStatus = 'initialized';
    } else {
      if (!environment.test)
        console.error(
          "Service provider can not be created! Auth Service and Tracking service won't work properly"
        );
      this.serviceProviderStatus = 'error';
    }
  }
}
