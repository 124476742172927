<div class="header">
  <p class="sd-1-0-1-display sd-1-0-1-display--size-3xl">Ihre Veranstaltungen</p>
  <sd-4-0-0-link
    size="inherit"
    (click)="onShowAllEventsClick($event)"
    href="/veranstaltungen"
  >
    Ihre Veranstaltungen anzeigen
    <sd-4-0-0-icon
      name="system/arrow-right"
      library="default"
      color="currentColor"
      class="icon"
    ></sd-4-0-0-icon>
  </sd-4-0-0-link>
</div>
<div class="loading-wrapper" *ngIf="this.loading">
  <sd-4-0-0-spinner color="primary"></sd-4-0-0-spinner>
</div>
<div class="body" *ngIf="!this.loading && this.status.length > 0">
  <sd-4-0-0-teaser class="primary-teaser" variant="primary" breakpoint="9999">
    <h2
      class="sd-1-0-1-headline sd-1-0-1-headline--size-xl sd-1-0-1-headline--inverted"
      slot="headline"
    >
      Wir würden uns sehr freuen, Sie bei einer unserer bevorstehenden
      Veranstaltungen willkommen zu heißen.
    </h2>
    <sd-4-0-0-link
      (click)="onShowAllEventsClick($event)"
      href="/veranstaltungen"
      inverted
    >
      Ihre Veranstaltungen zeigen
    </sd-4-0-0-link>
  </sd-4-0-0-teaser>
  <div class="row" [style]="'--var-columns: ' + this.status.length">
    <iko-va-event-widget-card
      *ngFor="let s of this.status"
      [participantStatus]="s"
      class="teaser"
    >
    </iko-va-event-widget-card>
  </div>
</div>
<div *ngIf="!this.loading && this.status.length === 0">
  <iko-va-error-message *ngIf="this.status.length === 0" [errorName]="'Keine Treffer.'" [errorMessage]="'Es wurden keine Veranstaltungen gefunden.'"></iko-va-error-message>
</div>
