<div class="meta-information">
  <div class="meta-line">
    <sd-4-0-0-icon
      class="meta-icon"
      name="content/calendar"
      library="default"
      color="currentColor"

    ></sd-4-0-0-icon>
    <p class="sd-1-0-1-meta meta-paragraph">
      {{ this.getFormattedDate() || "Keine Termin(e)" }}
    </p>
  </div>
  <div class="meta-line">
    <div style="display: block; width: 1.25em;">
    <sd-4-0-0-icon
      class="meta-icon"
      name="content/location"
      library="default"
      color="currentColor"

    ></sd-4-0-0-icon>
  </div>
    <p class="sd-1-0-1-meta meta-paragraph" [innerHTML]="this.getFormattedCities()">
      
    </p>
  </div>
</div>
