import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  catchError,
  concatMap,
  forkJoin,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';

import { DateUtility } from '../helpers/DateUtility';
import { EventServiceHelper } from '../interfaces/EventService';
import { HttpServiceHelper } from '../interfaces/HttpService';
import { contact } from '../models/contact';
import { crmikpk_participantstatus } from '../models/crmikpk_participantstatus';
import { msevtmgt_event } from '../models/msevtmgt_event';
import { HTTP_HELPER } from '../providers/http.helper.provider';
import { queries } from '../queries/';
import { DynamicsAPIResponse } from '../types/DynamicsAPIResponse';

import { AuthService } from './Auth.service';
import { UserService } from './User.service';

@Injectable()
export class EventService implements EventServiceHelper {
  private authToken: string | undefined;
  private _participantUrl = `${environment.crm.apiEndpoint}/${queries.participantStatusEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}&`;
  private _participantRtUrl = `${environment.crm.apiEndpoint}/${queries.participantStatusRtEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}&`;
  private _eventsUrl = `${environment.crm.apiEndpoint}/${queries.eventDetailsEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}&`;

  constructor(
    @Inject(HTTP_HELPER) private http: HttpServiceHelper,
    private userService: UserService,
    private authService: AuthService
  ) {}

  public getPublishedEventsByContactID(
    gone?: boolean
  ): Observable<crmikpk_participantstatus[] | null> {
    const url = this._participantUrl;
    const rtUrl = this._participantRtUrl;

    return from(this.authService.getAuthToken()).pipe(
      concatMap((authToken) => {
        this.authToken = authToken;
        return this.userService.getUser(authToken);
      }),
      concatMap((contacts) => {
        if (!contacts) return of(null);

        const queries: Observable<DynamicsAPIResponse<crmikpk_participantstatus> | null>[] =
          [];
        contacts.forEach((participant) => {
          queries.push(this.getContactEvents(url, participant, gone));
          queries.push(this.getContactEvents(rtUrl, participant, gone));
        });
        return forkJoin(queries);
      }),
      concatMap((apiResponse) => {
        if (!apiResponse || apiResponse.every((r) => r === null))
          return of(null);
        const allEvents = apiResponse
          .filter((r) => null !== r)
          .flatMap((r) => r!.value)
          .filter((e) => {
            if (
              (e.crmikpk_eventid
                ?.crmikpk_msevtmgt_event_msdyncrm_customerjourney_eventid &&
                e.crmikpk_eventid
                  ?.crmikpk_msevtmgt_event_msdyncrm_customerjourney_eventid
                  .length > 0) ||
              (e.crmikpk_eventid
                ?.crmikpk_msdynmkt_journey_eventid_msevtmgt_event &&
                e.crmikpk_eventid
                  ?.crmikpk_msdynmkt_journey_eventid_msevtmgt_event.length > 0)
            ) {
              return (
                e.crmikpk_eventid.crmikpk_msevtmgt_event_msdyncrm_customerjourney_eventid?.some(
                  (cj) => {
                    const date = DateUtility.convertToDate(
                      cj.msdyncrm_startdatetime,
                      true
                    );
                    return date && date <= new Date();
                  }
                ) ||
                e.crmikpk_eventid.crmikpk_msdynmkt_journey_eventid_msevtmgt_event?.some(
                  (cj) => {
                    const date = DateUtility.convertToDate(
                      cj.msdynmkt_journeystarttime,
                      true
                    );
                    return date && date <= new Date();
                  }
                )
              );
            }
            return false;
          })
          .sort(
            (a, b) =>
              (a.crmikpk_currentstatusdetailcode ?? 0) -
              (b.crmikpk_currentstatusdetailcode ?? 0)
          );
        const distinctEvents = allEvents.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                ((o.crmikpk_child_event_id || item.crmikpk_child_event_id) &&
                  o.crmikpk_child_event_id?.msevtmgt_eventid ===
                    item.crmikpk_child_event_id?.msevtmgt_eventid) ||
                o.crmikpk_eventid?.msevtmgt_eventid ===
                  item.crmikpk_eventid?.msevtmgt_eventid
            )
        );

        return of(distinctEvents);
      }),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  private getContactEvents(
    url: string,
    participant: contact,
    gone?: boolean
  ): Observable<DynamicsAPIResponse<crmikpk_participantstatus> | null> {
    let subquery = `participantid=${participant.contactid}`;
    const today = new Date();
    if (!gone) {
      subquery += `&startdate=${encodeURIComponent(today.toISOString())}`;
    } else {
      // Get Events 2 years ago
      subquery += `&startdate=${encodeURIComponent(
        new Date(
          today.getFullYear() - 2,
          today.getMonth(),
          today.getDate()
        ).toISOString()
      )}`;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
      }),
    };

    return this.http
      .get<DynamicsAPIResponse<crmikpk_participantstatus>>(
        url + subquery,
        httpOptions
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
          }
          console.error(errorMessage);
          //catch error 404 and 400, because of realtime marketing change
          //one api might not be found due to version of apim (404) or
          //one entity in crm might not be found (400)
          if (error.status === 404 || error.status === 400) {
            return of(null);
          }
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  public getParticipantStatus(
    eventId: string
  ): Observable<crmikpk_participantstatus[] | null> {
    return from(this.authService.getAuthToken()).pipe(
      concatMap((authToken) => {
        this.authToken = authToken;
        return this.userService.getUser(authToken);
      }),
      concatMap((contacts) => {
        if (!contacts || 0 === contacts.length) return of(null);
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.authToken,
          }),
        };

        const queries: Observable<DynamicsAPIResponse<crmikpk_participantstatus> | null>[] =
          [];
        contacts.forEach((participant) => {
          const subquery = `participantid=${participant.contactid}&eventid=${eventId}`;
          queries.push(
            this.http
              .get<DynamicsAPIResponse<crmikpk_participantstatus> | null>(
                this._participantUrl + subquery,
                httpOptions
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  let errorMessage = '';
                  if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                  } else {
                    // server-side error
                    errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                  }
                  console.error(errorMessage);
                  //catch error 404 and 400, because of realtime marketing change
                  //one api might not be found due to version of apim (404) or
                  //one entity in crm might not be found (400)
                  if (error.status === 404 || error.status === 400) {
                    return of(null);
                  }
                  return throwError(() => new Error(errorMessage));
                })
              )
          );
          queries.push(
            this.http
              .get<DynamicsAPIResponse<crmikpk_participantstatus> | null>(
                this._participantRtUrl + subquery,
                httpOptions
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  let errorMessage = '';
                  if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                  } else {
                    // server-side error
                    errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                  }
                  console.error(errorMessage);
                  //catch error 404 and 400, because of realtime marketing change
                  //one api might not be found due to version of apim (404) or
                  //one entity in crm might not be found (400)
                  if (error.status === 404 || error.status === 400) {
                    return of(null);
                  }
                  return throwError(() => new Error(errorMessage));
                })
              )
          );
        });
        return forkJoin(queries);
      }),
      concatMap((apiResponse) => {
        if (!apiResponse) return of(null);
        return of(apiResponse.filter((r)=>null !== r).flatMap((r) => r!.value));
      }),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  public getEventByID(
    guid: string,
    isParent: boolean = false
  ): Observable<msevtmgt_event | null> {
    return from(this.authService.getAuthToken()).pipe(
      concatMap((token) => {
        let subquery = `eventid=${guid}`;
        if (isParent) {
          subquery += '&isparent=1';
        }

        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        };

        return this.http
          .get<DynamicsAPIResponse<msevtmgt_event>>(
            this._eventsUrl + subquery,
            httpOptions
          )
          .pipe(
            concatMap((apiResponse) => {
              if (!apiResponse || 0 === apiResponse.value.length)
                return of(null);

              const event = apiResponse.value[0];
              return of(event);
            }),
            catchError((err) => {
              console.error(err);
              return of(null);
            })
          );
      })
    );
  }
}
