import { Component, Input } from '@angular/core';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'iko-va-error-message',
  templateUrl: './error-message.component.html',
  styleUrl: './error-message.component.css',
})
export class ErrorMessageComponent {
  @Input() errorName?: string = 'Es ist ein Fehler aufgetreten.';
  @Input() errorMessage?: string | null;
  @Input() action?: (e:Event)=> void;
  @Input() buttonText?: string|null;

  constructor(private trackingService:TrackingService){}

  buttonaction(e:Event){
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Click '${this.buttonText}'`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementType: 'Button',
        elementLabel: this.buttonText?this.buttonText:"Error Message Button",
        target: 'error-message',
        customECommerceParameter: {
          48: 'sd-2-11-0-button',
          49: 'sd-2-11-0',
          50: environment.app.NAME,
        },
      },
    });
    this.action && this.action(e);
  }
}
