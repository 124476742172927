import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CancelRegistrationModalComponent } from 'src/app/components/cancel-registration-modal/cancel-registration-modal.component';
import { SignupFormModalComponent } from 'src/app/components/signup-form-modal/signup-form-modal.component';
import { ROUTE_IDS } from 'src/app/config';
import {AppInsightsService} from 'src/app/helpers/AppInsights';
import { CalendarICSFileHelper } from 'src/app/helpers/CalendarICSFileHelper';
import { DateUtility } from 'src/app/helpers/DateUtility';
import {
  getEventName,
  getImageByEvent,
  isInPast,
  isInPastDate,
  isParentEventStatus,
  isRoadshow,
} from 'src/app/helpers/EventHelpers';
import { extractContent } from 'src/app/helpers/HTMLSanitizer';
import { SanitizeHtmlPipe } from 'src/app/helpers/SanitizeHtmlPipe';
import { contact } from 'src/app/models/contact';
import {
  crmikpk_currentstatusdetailcodeEnum,
  crmikpk_participantstatus,
} from 'src/app/models/crmikpk_participantstatus';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { msevtmgt_session } from 'src/app/models/msevtmgt_session';
import { msevtmgt_signup } from 'src/app/models/msevtmgt_signup';
import { msevtmgt_speaker } from 'src/app/models/msevtmgt_speaker';
import { AuthService } from 'src/app/services/Auth.service';
import { CacheService } from 'src/app/services/Cache.service';
import { EventService } from 'src/app/services/Event.service';
import { RegistrationService } from 'src/app/services/Registration.service';
import { RoutingService } from 'src/app/services/Routing.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { UserService } from 'src/app/services/User.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'va-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
})
export class EventDetailsComponent implements OnInit {
  @ViewChild(CancelRegistrationModalComponent)
  cancelRegistrationModal!: CancelRegistrationModalComponent;

  @ViewChild(SignupFormModalComponent)
  signupFormModal!: SignupFormModalComponent;

  event: msevtmgt_event | null = null;
  event_success_msg: msevtmgt_event | null = null;
  selectedChildEvent: msevtmgt_event | null = null;
  speakers?: msevtmgt_speaker[];
  sessions?: msevtmgt_session[];

  loading: boolean = true;
  valid: boolean = false;
  error: string | null = null;

  participantStatusEventId: string | null | undefined = null;
  contactId: string | null = null;
  isMultiDayEvent: boolean = false;
  registrationInProgress:boolean = false;

  constructor(
    private eventService: EventService,
    private authService: AuthService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private trackingService: TrackingService,
    private routingService: RoutingService,
    private appInsights: AppInsightsService
  ) {}

  async ngOnInit() {
    await this.trackingService.init();

    const urlParams = new URLSearchParams(window.location.search);
    const urlReadableEventId = urlParams.get('id')!;
    const urlChildEventId = urlParams.get('cid');
    const isParent =
      urlParams.get('isParent') === '1' && null === urlChildEventId;

    const readableEventID =
      !isParent && urlChildEventId != null
        ? urlChildEventId
        : urlReadableEventId;

    this.eventService.getParticipantStatus(urlReadableEventId).subscribe({
      next: (participantStatus: crmikpk_participantstatus[] | null) => {
        const participant = this.initParticipant(participantStatus);
        const eventId = this.initEvent(participant, readableEventID);
        this.appInsights.trackEvent("getEventByID", {"EventId":eventId.id, "IsParent":eventId.isParent});
        this.eventService.getEventByID(eventId.id, eventId.isParent).subscribe({
          next: (event: msevtmgt_event | null) => {
            this.event = event;
            this.loading = false;
            if (this.event == null || this.event.msevtmgt_eventid == null) {
              this.error = `Die Veranstaltung konnte nicht geladen werden.`;
            } else {
              this.valid = true;
              this.initChildEventSelection();

              this.trackingService.track({
                eventInfo: {
                  eventType: 'Page',
                  eventName: `Load Page`,
                },
                attributes: {
                  pageName: 'Veranstaltungsdetailseite',
                  pageUrl: window.location.href,
                  pageContentId: `IK.Veranstaltungen.${this.getTitle()}`,
                  pageInstanceName: window.location.origin,
                  target: window.location.href,
                  customECommerceParameter: {
                    48: 'va-event-details',
                    49: environment.app.VERSION,
                    50: environment.app.NAME,
                  },
                },
              });
              this.appInsights.trackPageView(
                'Veranstaltungsdetailseite',
                window.location.href
              );
            }
          },
          error: (error) => {
            this.error = error.message;
            console.error(error);
            this.loading = false;
            this.valid = false;
            this.appInsights.trackException(error, SeverityLevel.Critical);
          },
        });
      },
      error: (error) => {
        this.error = error.message;
        console.error(error);
        this.loading = false;
        this.valid = false;
        this.appInsights.trackException(error, SeverityLevel.Critical);
      },
    });
  }

  /**
   * Get first valid participantstatus from array of status
   * @param participantStatus array of participantstatus from Dynamics crm
   * @returns first valid participantstatus
   */
  initParticipant(participantStatus: crmikpk_participantstatus[] | null) {
    let participant = participantStatus?.find(
      (p) =>
        p.crmikpk_currentstatusdetailcode ===
          crmikpk_currentstatusdetailcodeEnum.Registriert ||
        p.crmikpk_currentstatusdetailcode ===
          crmikpk_currentstatusdetailcodeEnum.Check_In
    );

    if (participant) {
      this.participantStatusEventId =
        participant.crmikpk_child_event_id?.msevtmgt_eventid ??
        participant.crmikpk_eventid?.msevtmgt_eventid ??
        null;
    } else if (participantStatus && participantStatus.length > 0) {
      participant = participantStatus[0];
    }

    this.contactId =
      participant?.crmikpk_participantid?.contactid ??
      participantStatus?.at(0)?.crmikpk_participantid?.contactid ??
      null;
    return participant;
  }

  /**
   * Get id of event to load and check if is parent
   * @param participantStatus participantstatus from crm
   * @param eventId id of event to check
   * @returns id of event to load and status if is parent
   */
  initEvent(
    participantStatus: crmikpk_participantstatus | undefined,
    eventId: string
  ): { id: string; isParent: boolean } {
    if (!eventId) {
      if (participantStatus?.crmikpk_child_event_id?.msevtmgt_eventid) {
        eventId = participantStatus?.crmikpk_child_event_id?.msevtmgt_eventid;
      } else if (participantStatus?.crmikpk_eventid?.msevtmgt_eventid) {
        eventId = participantStatus?.crmikpk_eventid?.msevtmgt_eventid;
      }
    }
    let isParent = isParentEventStatus(participantStatus, eventId);
    //if only one child is present on parent event status, load child event
    if (
      isParent &&
      participantStatus?.crmikpk_eventid
        ?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid &&
      participantStatus?.crmikpk_eventid
        ?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid.length === 1
    ) {
      eventId =
        participantStatus.crmikpk_eventid
          .crmikpk_msevtmgt_event_msevtmgt_event_parenteventid[0]
          .msevtmgt_eventid;
      isParent = false;
    }
    return { id: eventId, isParent: isParent };
  }

  /**
   * Check for child of participantstatus or select first child
   */
  initChildEventSelection() {
    const children = this.getChildren(this.event);
    const numOfChildEvents = children?.length ?? 0;
    const child_event = children?.find(
      (ce) => ce.msevtmgt_eventid == this.participantStatusEventId
    );
    let childEventId = this.event?.msevtmgt_eventid;
    if (child_event != undefined) {
      childEventId = child_event.msevtmgt_eventid;
    } else if (children && numOfChildEvents > 0) {
      childEventId = children[0]!.msevtmgt_eventid;
    }
    this.childEventSelected(childEventId);
  }

  //#region DOM Helper
  getTitle() {
    if (this.event == null) {
      return '';
    } else {
      return getEventName(this.event);
    }
  }

  getHeading(): SafeHtml | string {
    if (this.event?.crmikpk_headingeventdescriptionportal) {
      const htmlSanitizer: SanitizeHtmlPipe = new SanitizeHtmlPipe(
        this.sanitizer
      );
      return htmlSanitizer.transform(
        this.event.crmikpk_headingeventdescriptionportal
      );
    }
    return this.getTitle();
  }

  getBannerImageURL() {
    return getImageByEvent(this.event);
  }

  isRoadshow(): boolean {
    return this.event != null ? isRoadshow(this.event) : false;
  }

  isEventInPast(selectedEventId: string): boolean {
    const event = this.getEventById(selectedEventId);
    if (event == null) {
      return false;
    } else {
      return isInPast(event);
    }
  }

  isRegistrationDateInPast(eventDate: Date|null|undefined): boolean {
    if(!eventDate) return false;
      return isInPastDate(eventDate);
  }

  isAllEventsInPast(): boolean {
    if (this.event == null) {
      return false;
    } else {
      if (this.isRoadshow()) {
        let counter = 0;
        this.getChildren(this.event)?.forEach((ev) => {
          if (!isInPast(ev)) {
            counter++;
          }
        });
        return counter == 0;
      } else {
        return isInPast(this.event);
      }
    }
  }

  isParticipantRegistered(): boolean {
    return this.participantStatusEventId != null && this.registrationInProgress !== true;
  }
  //#endregion

  public async downloadICSFile() {
    if (this.event != null) {
      const file = await CalendarICSFileHelper.generateICSFile(this.event);
      const href = file
        ? URL.createObjectURL(file)
        : `Error generating ics file ${file}`;

        this.appInsights.trackEvent("downloadICSFile", {"href":href});
      this.trackingService.track({
        eventInfo: {
          eventType: 'Action',
          eventName: `Click 'Im Kalender speichern'`,
        },
        category: {
          area: 'Content',
        },
        attributes: {
          target: href,
          elementType: 'Button',
          elementLabel: 'Im Kalender speichern',
          customECommerceParameter: {
            4: href,
            48: 'sd-4-0-0-button',
            49: 'sd-4-0-0',
            50: environment.app.NAME,
          },
        },
      });

      file && URL.revokeObjectURL(href);
    }
  }

  navigateToParent(e: Event) {
    if (!this.routingService) {
      this.routingService = new RoutingService();
    }
    e.preventDefault();
    window.history.pushState({}, '', '/veranstaltungen');
    this.routingService.dispatchRouteChangeEvent({
      page: ROUTE_IDS.HOME,
    });
  }

  childEventSelected(selectedEventId: string | undefined) {
    if (!this.event || !selectedEventId) {
      return;
    }

    const childEvents = this.getChildren(this.event);

    let selectedChild: msevtmgt_event | undefined = childEvents?.find(
      (child) => child.msevtmgt_eventid === selectedEventId
    );
    if (!selectedChild) {
      selectedChild = this.event;
    }
    if (selectedChild) {
      this.selectedChildEvent = selectedChild;
      this.getSessions();
      this.getSpeakers();
      this.setIsMultiDayEvent();
    }
  }

  //#region registration and cancellation
  doCancelRegistrationEvent() {
    this.participantStatusEventId = this.event?.msevtmgt_eventid;
  }

  onStartRegisterEvent(selectedEventId: string) {
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Click 'Jetzt Anmelden'`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementType: 'Button',
        elementLabel: 'Jetzt Anmelden',
        target: 'singup-form-modal',
        customECommerceParameter: {
          48: 'sd-4-0-0-button',
          49: 'sd-4-0-0',
          50: environment.app.NAME,
        },
      },
    });
    this.event_success_msg = null;

    this.authService.getAuthToken().then((token) => {
      this.userService.getUser(token).subscribe((contact: contact[] | null) => {
        if (contact != null && contact.length > 0) {
          this.openSignOnDialog(selectedEventId, contact[0]);
        }
      });
    });
  }

  openSignOnDialog(selectedEventId: string, contact: contact) {
    const selected_event = this.getEventById(selectedEventId);
    const event_title = extractContent(
      this.sanitizer.sanitize(
        SecurityContext.NONE,
        selected_event?.crmikpk_headingeventdescriptionportal ?? null
      )!
    );

    const start_date = new Date(selected_event!.msevtmgt_eventstartdate!);
    const end_date = new Date(selected_event!.msevtmgt_eventenddate!);
    const dayString = new Date(start_date).toLocaleString('de-de', {
      weekday: 'long',
    });
    const timeString = DateUtility.formatRangeTime(
      new Date(start_date),
      new Date(end_date),
      true,
      ' - ',
      false,
      false
    );

    const event_description = `${event_title} | ${dayString} | ${timeString}`;

    const signUpToEvent: msevtmgt_signup = {
      event_id: selectedEventId,
      readableevent_id:
        this.getEventById(selectedEventId)!.msevtmgt_readableeventid!,
      event_title: event_title,
      event_description: event_description,
      user: contact,
      registration: {
        salutation: contact.crmikpk_salutationid?.crmikpk_value ?? '',
        title: contact.crmikpk_academictitleid?.crmikpk_value ?? '',
        first_name: contact.firstname ?? '',
        last_name: contact.lastname ?? '',
        terms_of_use_prim: false,
        terms_of_use_ex: false,
      },
    };

    this.trackingService.track({
      eventInfo: {
        eventType: 'VirtualPage',
        eventName: `öffne Veranaltungsanmeldung Dialog`,
      },
      attributes: {
        pageUrl: window.location.href,
        virtualContentGroups: ['Zur_Veranstaltung_anmelden?'],
        customECommerceParameter: {
          48: 'iko-va-signup-form-modal',
          49: environment.app.VERSION,
          50: environment.app.NAME,
        },
      },
    });

    this.signupFormModal.open(signUpToEvent);
  }

  registrationSucessfulDelagate = this.registrationSucessful.bind(this);

  registrationSucessful(selectedEventId: string) {
    const selectedEvent = this.getEventById(selectedEventId);
    this.event_success_msg = selectedEvent;
    this.trackingService.track({
      eventInfo: {
        eventType: 'VirtualPage',
        eventName: `Anmeldung erfolgreich`,
      },
      attributes: {
        pageUrl: window.location.href,
        virtualContentGroups: ['Anmeldung_erfolgreich'],
        customECommerceParameter: {
          48: 'iko-va-registration-success-modal',
          49: environment.app.VERSION,
          50: environment.app.NAME,
        },
      },
    });
    if (selectedEvent != null) {
      this.participantStatusEventId = selectedEvent.msevtmgt_eventid;
      this.registrationInProgress = true;
      this.initChildEventSelection();
    }
    CacheService.removeEvents(false);
  }

  onTryCancelEvent(selectedEventId: string) {
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Click 'Teilnahme stornieren'`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementType: 'Button',
        elementLabel: 'Teilnahme stornieren',
        target: 'cancel-registration-form-modal',
        customECommerceParameter: {
          48: 'sd-4-0-0-button',
          49: 'sd-4-0-0',
          50: environment.app.NAME,
        },
      },
    });
    const selectedEvent = this.getEventById(selectedEventId);
    if (selectedEvent && null !== this.contactId) {
      this.cancelRegistrationModal.open(selectedEvent, this.contactId!);
    }
  }

  /**
   * Cancels event registration
   * @param event where user is registered and where the user wants to cancel his registration
   * @param contactid registered contact
   * @returns true if cancellation of event registration was successful. When error thrown return false. DO NOT THROW JS ERROR
   */
  cancelEventRegistration(
    event: msevtmgt_event,
    contactid: string,
    service: RegistrationService
  ): Promise<boolean> {
    if (
      (event.crmikpk_parenteventid?.msevtmgt_eventid ||
        event.msevtmgt_eventid) &&
      null !== contactid
    ) {
      return new Promise<boolean>((resolve) => {
        try {
          service
            .cancelRegistration(
              event.crmikpk_parenteventid?.msevtmgt_eventid ??
                event.msevtmgt_eventid!,
              contactid!
            )
            .subscribe({
              next: () => {
                this.participantStatusEventId = null;

                CacheService.removeEvents(false);
                resolve(true);
              },
              error: (err) => {
                console.error(err);
                this.appInsights.trackException(err, SeverityLevel.Error);
              },
            });
        } catch (err) {
          console.error(err);
          CacheService.removeEvents(false);
          resolve(false);
        }
      });
    } else {
      return new Promise<boolean>((resolve) => resolve(false));
    }
  }

  cancelRegistrationSucessful() {
    return () => {
      this.participantStatusEventId = null;
      this.initChildEventSelection();
    };
  }
  //#endregion

  //#region event details
  getEventById(selectedEventId: string) {
    if (this.event == null) {
      return null;
    }
    if (this.event.msevtmgt_eventid == selectedEventId) {
      return this.event;
    }

    if (this.event.crmikpk_parenteventid?.msevtmgt_eventid == selectedEventId) {
      return this.event.crmikpk_parenteventid;
    }

    const children = this.getChildren(this.event);
    if (children == null || children == undefined) {
      return null;
    }

const firstChild = children.find(
  (child) => child.msevtmgt_eventid === selectedEventId
)!;
firstChild["crmikpk_parenteventid"] = this.event.crmikpk_parenteventid??this.event;
    return firstChild;
  }

  getChildren(event: msevtmgt_event | null): msevtmgt_event[] | undefined {
    let children: msevtmgt_event[] | undefined;
    if (
      event?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid &&
      event?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid.length > 0
    ) {
      children = event?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid;
    } else if (
      event?.crmikpk_parenteventid
        ?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid
    ) {
      children =
        event?.crmikpk_parenteventid
          ?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid;
    }

    return children?.sort((a, b) => {
      let aNum = 0;
      let bNum = 0;
      if (DateUtility.isValidTimestamp(a.msevtmgt_eventstartdate))
        aNum = new Date(a.msevtmgt_eventstartdate!).getTime();
      else if (DateUtility.isValidTimestamp(a.msevtmgt_eventenddate))
        aNum = new Date(a.msevtmgt_eventenddate!).getTime();
      if (DateUtility.isValidTimestamp(b.msevtmgt_eventstartdate))
        bNum = new Date(b.msevtmgt_eventstartdate!).getTime();
      else if (DateUtility.isValidTimestamp(b.msevtmgt_eventenddate))
        bNum = new Date(b.msevtmgt_eventenddate!).getTime();
      return aNum - bNum;
    });
  }  

  private getSessions() {
    const sortSessions = (a: msevtmgt_session, b: msevtmgt_session) => {
      const firstStart = DateUtility.convertToDate(a.msevtmgt_starttime);
      const secondStart = DateUtility.convertToDate(b.msevtmgt_starttime);

      if (!firstStart || !secondStart) return 0;
      return firstStart.getTime() - secondStart.getTime();
    };

    this.sessions =
      this.selectedChildEvent?.msevtmgt_event_msevtmgt_session_Event?.sort(
        sortSessions
      );
  }

  private getSpeakers() {
    let speakers: msevtmgt_speaker[] = [];
    for (const session of this.sessions || []) {
      const engagements = (
        session.msevtmgt_msevtmgt_session_msevtmgt_speakerengagement_Session ||
        []
      ).map((engangement) => engangement.msevtmgt_Speaker);

      speakers = speakers.concat(engagements as msevtmgt_speaker[]);
    }
    //get speakers unique
    this.speakers = speakers.filter(
      (value, index, array) =>
        array.findIndex(
          (s) => s.msevtmgt_speakerid === value.msevtmgt_speakerid
        ) === index
    );
  }

  private setIsMultiDayEvent() {
    const e = this.selectedChildEvent;
    if (e?.msevtmgt_eventstartdate && e?.msevtmgt_eventenddate) {
      this.isMultiDayEvent = !DateUtility.isSameDate(
        new Date(e.msevtmgt_eventstartdate),
        new Date(e.msevtmgt_eventenddate),
        true
      );
    }
  }
  //#endregion
  
}
