import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackingService } from 'src/app/services/Tracking.service';
import { Filter } from 'src/app/types/Filter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'iko-va-chip-filter',
  templateUrl: './chip-filter.component.html',
  styleUrls: ['./chip-filter.component.css'],
})
export class ChipFilterComponent<T> implements OnInit {
  @Input() filters: Filter<T>[] = [];
  @Output() onFilter = new EventEmitter<Filter<T>>();
  currentFilter: string = '';

  constructor(private trackingService: TrackingService) {}

  async ngOnInit() {
    if (this.filters?.length > 0) this.currentFilter = this.filters[0].key;
    await this.trackingService.init();
  }

  protected onChipClicked(clickedFilter: Filter<T>) {
    // only call filter if user clicks a new filter otherwise do nothing
    if (this.currentFilter == clickedFilter.key) return;

    this.currentFilter = clickedFilter.key;
    this.onFilter.emit(clickedFilter);
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Tag '${clickedFilter.label}' clicked`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementLabel: clickedFilter.label,
        elementType: 'Button',
        target: clickedFilter.key,
        customECommerceParameter: {
          48: 'sd-4-0-0-tag',
          49: 'sd-4-0-0',
          50: environment.app.NAME,
        },
      },
    });
  }
}
