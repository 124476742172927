<div class="social-media-wrapper">
  <sd-4-0-0-link
    [href]="speaker?.msevtmgt_linkedin"
    *ngIf="!!speaker?.msevtmgt_linkedin"
    target="_blank"
    (click)="this.onLinkClicked('linkedin', speaker?.msevtmgt_linkedin)"
  >
    <sd-4-0-0-icon
      class="social-media-icon"
      name="system/linkedin"
      library="default"
      color="currentColor"
    ></sd-4-0-0-icon>
  </sd-4-0-0-link>
</div>
