import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarICSFileHelper } from 'src/app/helpers/CalendarICSFileHelper';
import { DateUtility } from 'src/app/helpers/DateUtility';
import { isInPast, isInPastDate } from 'src/app/helpers/EventHelpers';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'iko-va-roadshow-registration',
  templateUrl: './roadshow-registration.component.html',
  styleUrls: ['./roadshow-registration.component.css'],
})
export class RoadshowRegistrationComponent {
  @Input() childEvents: msevtmgt_event[] | undefined;
  @Input() participantStatus: string | null | undefined;
  @Output() onRegisterEvent: EventEmitter<string> = new EventEmitter();
  @Output() onCancelEvent: EventEmitter<string> = new EventEmitter();

  constructor(private trackingService: TrackingService) {}

  isParticipantRegistered(event_id: string): boolean {
    return this.participantStatus == event_id;
  }

  isParticipantRegisteredAtAnyChildEvent(): boolean {
    return this.participantStatus != null;
  }

  doCancelRegistrationEvent(event_id: string) {
    const event = this.getEventFromId(event_id);
    if (event != null && !isInPast(event)) {
      this.onCancelEvent.emit(event_id);
    }
  }

  doRegisterEvent(event_id: string) {
    if (!this.isParticipantRegisteredAtAnyChildEvent()) {
      this.onRegisterEvent.emit(event_id);
    }
  }

  isInPast(event_id: string) {
    const event = this.getEventFromId(event_id);
    if (event != null) {
      return isInPast(event);
    } else {
      return true;
    }
  }

  isRegistrationDateInPast(event_id: string) {
    const event = this.getEventFromId(event_id);
    if (event != null && event.msevtmgt_stopwebsiteregistrationson) {
      return isInPastDate(event.msevtmgt_stopwebsiteregistrationson);
    } else {
      return false;
    }
  }

  public async downloadICSFile(event_id: string) {
    const event = this.getEventFromId(event_id);
    if (event != null && !isInPast(event)) {
      const file = await CalendarICSFileHelper.generateICSFile(event);
      const href = file
        ? URL.createObjectURL(file)
        : `Error generating ics file ${file}`;

      this.trackingService.track({
        eventInfo: {
          eventType: 'Action',
          eventName: `Click 'Im Kalender speichern'`,
        },
        category: {
          area: 'Content',
        },
        attributes: {
          target: href,
          elementType: 'Button',
          elementLabel: 'Im Kalender speichern',
          customECommerceParameter: {
            4: href,
            48: 'sd-4-0-0-button',
            49: 'sd-4-0-0',
            50: environment.app.NAME,
          },
        },
      });

      file && URL.revokeObjectURL(href);
    }
  }

  private getEventFromId(event_id: string): msevtmgt_event | undefined {
    const event = this.childEvents?.find(
      (ev) => ev.msevtmgt_eventid == event_id
    );
    return event;
  }

  public getEventDate(event?: msevtmgt_event): string {
    let date = "";
    try{
    if (event?.msevtmgt_eventstartdate) {
      date =  DateUtility.format(new Date(event.msevtmgt_eventstartdate), false, false, false);
    } else if (event?.msevtmgt_eventenddate) {
      date =  DateUtility.format(new Date(event.msevtmgt_eventenddate), false, false, false);
    }
  }
  catch(err){
    console.error(err);
  }
    return date.replace(".","").toUpperCase().split(" ").reverse().join("</br>");
  }
}
