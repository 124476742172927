<p>
  <sd-2-11-0-link
    size="inherit"
    class="parent-link"
    href="/veranstaltungen"
    (click)="navigateToParent($event)"
    id="lnkRtnHme"
  >
    Veranstaltungen
  </sd-2-11-0-link>
  <sd-2-11-0-icon
    class="divider"
    name="system/chevron-right"
    library="default"
    color="currentColor"

  ></sd-2-11-0-icon>
  <span class="current-event">{{ getTitle() }}</span>
</p>
