<div class="text-left text-black">
  <q class="block mb-6 text-3xl leading-tight">
    {{getQuoteTextNoHtml()}}
  </q>
  <div class="gap-4 flex items-center">
    <div class="image-container overflow-hidden rounded-full h-16 w-16">
      <img src="{{getSpeakerImageURL()}}" alt="{{getSpeakerTitleAndName()}}" class="h-16" />
    </div>

    <div>
      <p class="sd-1-0-1-paragraph font-bold mb-1">{{getSpeakerTitleAndName()}}</p>
      <p class="sd-1-0-1-paragraph sd-1-0-1-paragraph--size-sm">{{event?.crmikpk_Quotefrom?.crmikpk_speakerjobtitle}}
      </p>
    </div>
  </div>
</div>