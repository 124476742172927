import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { msevtmgt_typeEnum } from 'src/app/models/msevtmgt_customregistrationfield';
import { custom_field_response } from 'src/app/models/registration';

@Component({
  selector: 'iko-va-registration-custom-field',
  templateUrl: './registration-custom-field.component.html',
  styleUrls: ['./registration-custom-field.component.css'],
})
export class RegistrationCustomFieldComponent {
  @Input() regField: custom_field_response | undefined;
  @ViewChild('elem_regfldSimple') elem_regfldSimple?: ElementRef;
  @ViewChild('elem_regfldBoolean') elem_regfldBoolean?: ElementRef;
  @ViewChild('elem_regfldSingleChoice') elem_regfldSingleChoice?: ElementRef;
  @ViewChildren('elem_regfldMultiChoice', { read: ElementRef })
  elem_regfldMultiChoice?: QueryList<ElementRef>;

  getFieldValue(): string | undefined {
    let result = null;
    switch (this.getFldType()) {
      case msevtmgt_typeEnum.Simpletext:
        result = this.elem_regfldSimple?.nativeElement.value;
        break;

      case msevtmgt_typeEnum.Booleanyes_no: {
        const noyes = this.elem_regfldBoolean?.nativeElement.checked;
        if (
          !noyes &&
          this.regField?.custom_registration_field
            .msevtmgt_customregistrationfield.msevtmgt_isrequired
        ) {
          result = undefined;
        } else {
          result = noyes ? 'Yes' : 'No';
        }
        break;
      }
      case msevtmgt_typeEnum.Singlechoice: {
        const singleValue: number =
          this.elem_regfldSingleChoice?.nativeElement.value;
        const choices = this.getChoices();
        result = singleValue < choices.length ? choices[singleValue] : '';
        break;
      }
      case msevtmgt_typeEnum.Multiplechoice: {
        const mult_choices = this.getChoices();
        if (!this.elem_regfldMultiChoice) {
          break;
        }
        for (let i = 0; i < this.elem_regfldMultiChoice.length!; i++) {
          if (
            this.elem_regfldMultiChoice?.get(i)?.nativeElement.checked == true
          ) {
            if (result == null) {
              result =
                mult_choices[
                  this.elem_regfldMultiChoice?.get(i)?.nativeElement.value
                ];
            } else {
              result +=
                ', ' +
                mult_choices[
                  this.elem_regfldMultiChoice?.get(i)?.nativeElement.value
                ];
            }
          }
        }
        break;
      }
    }

    if (!result) return undefined;

    return result;
  }

  getFldType(): msevtmgt_typeEnum {
    if (this.regField == null) {
      return msevtmgt_typeEnum.Simpletext;
    }
    return this.regField?.custom_registration_field
      .msevtmgt_customregistrationfield.msevtmgt_type;
  }

  getChoices(): string[] {
    return this.regField!.custom_registration_field.msevtmgt_customregistrationfield.msevtmgt_choices?.split(
      '\n'
    );
  }

  getTitle(): string | undefined {
    const fldDef =
      this.regField?.custom_registration_field
        ?.msevtmgt_customregistrationfield;
    return `${fldDef?.msevtmgt_text}${fldDef?.msevtmgt_isrequired ? ' *' : ''}`;
  }
}
