<iko-va-full-screen-loader *ngIf="loading"></iko-va-full-screen-loader>

<div *ngIf="!loading && authState === 'authenticated'">
  <va-event-overview *ngIf="route?.id == routes['home'].id"></va-event-overview>
  <va-event-details
    *ngIf="route?.id == routes['details'].id"
  ></va-event-details>
</div>

<div *ngIf="!loading && authState === 'unauthenticated'">
  <div *ngIf="route?.id == routes['home'].id">
    <sd-4-0-0-button
      style="margin-bottom: 32px"
      title
      variant="secondary"
      size="lg"
      type="button"
      (click)="login()"
      >Login</sd-4-0-0-button
    >
  </div>
  <va-event-details
    *ngIf="route?.id == routes['details'].id"
  ></va-event-details>
</div>
