import { Component, Input } from '@angular/core';
import { crmikpk_participantstatus } from 'src/app/models/crmikpk_participantstatus';

@Component({
  selector: 'iko-va-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css'],
})
export class EventListComponent {
  @Input() events: crmikpk_participantstatus[] = [];
  @Input() showFirstElement: boolean = false;
}
