<iko-va-container>
  <h4 class="sd-2-11-0-headline">Veranstaltungsübersicht</h4>
  <iko-va-chip-filter (onFilter)="onFilterEvents($event)" [filters]="filters" />

  <div class="loading-spinner justify-content-center" *ngIf="loading">
    <sd-2-11-0-spinner color="primary"></sd-2-11-0-spinner>
  </div>
  <iko-va-error-message *ngIf="!loading && error != null" [errorMessage]="error"></iko-va-error-message>
</iko-va-container>

<iko-va-event-list
  *ngIf="currentFilter != filters[0].key && !loading && error == null"
  [events]="visibleEvents"
  [showFirstElement]="false"
/>

<iko-va-container>
  <h4 *ngIf="currentFilter != filters[0].key" class="sd-2-11-0-headline">Weitere Veranstaltungen für Sie</h4>
</iko-va-container>

<iko-va-event-list
  *ngIf="!loading && error == null"
  [events]="upcomingEvents"
  [showFirstElement]="true"
/>
