import { Component, Input } from '@angular/core';
import { DateUtility } from 'src/app/helpers/DateUtility';
import { SpeakerHelper } from 'src/app/helpers/SpeakerHelper';
import { msevtmgt_session } from 'src/app/models/msevtmgt_session';
import { msevtmgt_session_speaker, msevtmgt_speaker } from 'src/app/models/msevtmgt_speaker';

@Component({
  selector: 'iko-va-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
})
export class AgendaComponent {
  @Input() sessions: msevtmgt_session[] | undefined;
  @Input() multipleDays:boolean | undefined;

  getSpeakerNameAndTitle(speaker?: msevtmgt_speaker) {
    let name = speaker?.msevtmgt_name;
    if (speaker?.msevtmgt_title != null && speaker?.crmikpk_speakerjobtitle != null) {
        name = speaker?.msevtmgt_title + ' ' + name + ', ' + speaker?.crmikpk_speakerjobtitle;
    } else {
        if (speaker?.msevtmgt_title != null) {
            name = speaker?.msevtmgt_title + ' ' + name;
        }

        if (speaker?.crmikpk_speakerjobtitle != null) {
            name += ', ' + speaker?.crmikpk_speakerjobtitle;
        }
    }
    return name;
}

  getStartTime(session:msevtmgt_session): string{
    const starttime = DateUtility.convertToDate(session.msevtmgt_starttime);
    if(starttime)
    return DateUtility.formatTime(starttime,true);
    //session.msevtmgt_starttime | date : "H:mm"

    return '';
  }
  getStartDate(session:msevtmgt_session): string{
    if(!this.multipleDays){
      return '';
    }
    const starttime = DateUtility.convertToDate(session.msevtmgt_starttime);
    if(starttime)
      return DateUtility.format(starttime,true,false, true);
    //session.msevtmgt_starttime | date : "H:mm"

    return '';
  }

  getSpeakerImage(speaker: msevtmgt_session_speaker) {
    return SpeakerHelper.getSpeakerImage(speaker.msevtmgt_Speaker);
  }
}
