import { Component, Input } from '@angular/core';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';

@Component({
  selector: 'iko-va-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css']
})
export class TopicsComponent {
  @Input() event: msevtmgt_event | undefined;
}
