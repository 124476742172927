import { Component, Input, OnInit } from '@angular/core';
import { msevtmgt_speaker } from 'src/app/models/msevtmgt_speaker';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

type TargetPlatform = "linkedin";

@Component({
  selector: 'iko-va-social-media-links',
  templateUrl: './social-media-links.component.html',
  styleUrls: ['./social-media-links.component.css'],
})
export class SocialMediaLinksComponent implements OnInit {
  @Input() speaker?: msevtmgt_speaker | null;

  constructor(private trackingService: TrackingService) {}

  ngOnInit() {
    this.trackingService.init();
  }

  public onLinkClicked(targetPlatform: TargetPlatform, target?: string) {
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Click social media link`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        externalTarget: target,
        elementType: 'Link',
        elementLabel: targetPlatform,
        customECommerceParameter: {
          48: 'sd-4-0-0-link',
          49: 'sd-4-0-0',
          50: environment.app.NAME,
        },
      },
    });
  }
}
