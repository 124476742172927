import { Component, Input } from '@angular/core';
import { SpeakerHelper } from 'src/app/helpers/SpeakerHelper';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';

@Component({
  selector: 'iko-va-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css'],
})
export class QuoteComponent {
  @Input() event: msevtmgt_event | undefined;

  public getSpeakerImageURL() {
    return SpeakerHelper.getSpeakerImage(this.event?.crmikpk_Quotefrom);
  }

  public getQuoteTextNoHtml() {
    if (!this.event || !this.event.crmikpk_quote)
    {
      return '';
    }

    const doc = new DOMParser().parseFromString(this.event.crmikpk_quote, 'text/html');
    return doc.body.textContent || '';
  }

  public getSpeakerTitleAndName() {
    if (!this.event || !this.event.crmikpk_Quotefrom) return '';
    const speaker = this.event.crmikpk_Quotefrom;
    return `${speaker.msevtmgt_title || ""} ${speaker.msevtmgt_name}`;
  }
}
