import { HttpHeaders } from '@angular/common/http';
import { Inject,Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpOptions } from '../interfaces/HttpOptions';
import { HttpServiceHelper } from '../interfaces/HttpService';
import { HTTP_HELPER } from '../providers/http.helper.provider';

@Injectable({
  providedIn: 'root',
})
export class HttpRestService implements HttpServiceHelper {
  constructor(@Inject(HTTP_HELPER) private http: HttpServiceHelper) {}

  public get<T>(url: string, httpOptions?: HttpOptions): Observable<T> {
    if (!httpOptions) {
      httpOptions = { headers: new HttpHeaders() };
    }
    if (!httpOptions.headers.has('Content-Type')) {
      httpOptions.headers = httpOptions.headers.append(
        'Content-Type',
        'application/json; charset=utf-8'
      );
    }
    if (!httpOptions.headers.has('Accept')) {
      httpOptions.headers = httpOptions.headers.append(
        'Accept',
        'application/json'
      );
    }
    if (!httpOptions.headers.has('OData-MaxVersion')) {
      httpOptions.headers = httpOptions.headers.append('OData-MaxVersion', '4.0');
    }
    if (!httpOptions.headers.has('OData-Version')) {
      httpOptions.headers = httpOptions.headers.append('OData-Version', '4.0');
    }
    if(undefined !== environment.crm.apiSubscription && !httpOptions.headers.has('x-api-key'))
      httpOptions.headers = httpOptions.headers.append('x-api-key', environment.crm.apiSubscription);

    return new Observable((observer) => {
      this.http.get<T>(url, httpOptions).subscribe(observer);
    });
  }

  public post<T>(
    url: string,
    body: any,//eslint-disable-line @typescript-eslint/no-explicit-any
    httpOptions?: HttpOptions
  ): Observable<T> {
    if (!httpOptions) {
      httpOptions = { headers: new HttpHeaders() };
    }
    httpOptions.headers = httpOptions.headers.append(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    httpOptions.headers = httpOptions.headers.append(
      'Accept',
      'application/json'
    );
    if (!httpOptions.headers.has('OData-MaxVersion')) {
      httpOptions.headers = httpOptions.headers.append('OData-MaxVersion', '4.0');
    }
    if (!httpOptions.headers.has('OData-Version')) {
      httpOptions.headers = httpOptions.headers.append('OData-Version', '4.0');
    }
    if(undefined !== environment.crm.apiSubscription && !httpOptions.headers.has('x-api-key'))
    httpOptions.headers = httpOptions.headers.append('x-api-key', environment.crm.apiSubscription);

    return new Observable((observer) => {
      this.http.post<T>(url, body, httpOptions).subscribe(observer);
    });
  }
}
