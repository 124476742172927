import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CalendarICSFileHelper } from 'src/app/helpers/CalendarICSFileHelper';
import { DateUtility } from 'src/app/helpers/DateUtility';
import { getEventName,
  getImageByEvent
 } from 'src/app/helpers/EventHelpers';
import { ModalHelper } from 'src/app/helpers/ModalHelper';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';


type Event = msevtmgt_event | undefined | null;

@Component({
  selector: 'iko-va-registration-success-modal',
  templateUrl: './registration-success-modal.component.html',
  styleUrls: ['./registration-success-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationSuccessModalComponent extends ModalHelper {
  @HostBinding('class.isOpen') isModalOpen = false;

  @Output() eventChange = new EventEmitter<msevtmgt_event>();
  _event?: msevtmgt_event;
  @Input() set event(value: Event) {
    this._event = value === null ? undefined : value;
    if (value === undefined) {
      this.close();
    } else if (value !== null) {
      this.open();
    }
  }

  get event(): Event {
    return this._event;
  }

  constructor(private trackingService: TrackingService) {
    super({ enableCloseOnEscape: true });
    this.closeOnEscapeFunction = () => {
      this.isModalOpen = false;
    };
  }

  public async downloadICSFile() {
    if (this.event != null) {
      const file = await CalendarICSFileHelper.generateICSFile(this.event);
      const href = file
        ? URL.createObjectURL(file)
        : `Error generating ics file ${file}`;

      this.trackingService.track({
        eventInfo: {
          eventType: 'Action',
          eventName: `Click 'Im Kalender speichern'`,
        },
        category: {
          area: 'Content',
        },
        attributes: {
          target: href,
          elementType: 'Button',
          elementLabel: 'Im Kalender speichern',
          customECommerceParameter: {
            4: href,
            48: 'sd-2-11-0-button',
            49: 'sd-2-11-0',
            50: environment.app.NAME,
          },
        },
      });

      file && URL.revokeObjectURL(href);
    }
  }

  getBannerImageURL() {
    return getImageByEvent(this.event);
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  open() {
    this.isModalOpen = true;
    this._open();
  }

  close() {
    this.isModalOpen = false;
    this._close();
  }

  getEventTime(): string {
    if(!this.event || !DateUtility.isValidDate(this.event.msevtmgt_eventstartdate) || !DateUtility.isValidDate(this.event.msevtmgt_eventenddate)){
      return "";
    }
    try {
      const dayString = new Date(
        this.event.msevtmgt_eventstartdate!
      ).toLocaleString('de-de', { weekday: 'long' });
      const timeString = DateUtility.formatRangeTime(
        new Date(this.event.msevtmgt_eventstartdate!),
        new Date(this.event.msevtmgt_eventenddate!),
        true,
        ' - ',
        false,
        false
      );
      return `${dayString} | ${timeString}`;
    } catch (e) {
      console.error(e);
      return '';
    }
  }
  getTtile(): string {
    return getEventName(this._event);
  }
}
