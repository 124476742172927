import { createEvent, DateArray, EventAttributes } from 'ics';
import { msevtmgt_building } from 'src/app/models/msevtmgt_building';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';

import { DateUtility } from './DateUtility';
import { getEventName } from './EventHelpers';
import filenamify from './FilenameSanitizer';

export class CalendarICSFileHelper {
  public static async generateICSFile(event: msevtmgt_event) {
    if(!DateUtility.isValidDate(event.msevtmgt_eventstartdate)||!DateUtility.isValidDate(event.msevtmgt_eventenddate)){
      return;
    }
    const start = new Date(event.msevtmgt_eventstartdate!)!;
    const startData: DateArray = [
      start.getUTCFullYear(),
      start.getUTCMonth() + 1,
      start.getUTCDate(),
      start.getUTCHours(),
      start.getUTCMinutes(),
    ];

    const end = new Date(event.msevtmgt_eventenddate!)!;
    const endData: DateArray = [
      end.getUTCFullYear(),
      end.getUTCMonth() + 1,
      end.getUTCDate(),
      end.getUTCHours(),
      end.getUTCMinutes(),
    ];

    const eventTitle = getEventName(event);
    const eventName =
      eventTitle && eventTitle.length > 0 ? eventTitle : 'Veranstaltungstermin';

    const location = this.getLocation(event.msevtmgt_building);

    const calendarEvent: EventAttributes = {
      productId: 'ics',
      uid: event.msevtmgt_eventid,
      title: eventName,
      location,
      busyStatus: 'FREE',
      start: startData,
      startInputType: 'local',
      end: endData,
      endInputType: 'local',
      htmlContent:event.msevtmgt_calendarcontent || ''
    };

    const filename = filenamify(eventName, { replacement: '' }) + '.ics';
    type ICSFileCreationResponse = {
      data: File | null;
      error: Error | null;
    };
    const { error, data: file } = await new Promise<ICSFileCreationResponse>(
      (resolve, reject) => {
        createEvent(calendarEvent, (error, value) => {
          if (error) {
            reject({ error, data: null });
          }

          resolve({
            error: null,
            data: new File([value], filename, { type: 'text/calendar' }),
          });
        });
      }
    );

    if (error || file === null) {
      console.error(error);
      return;
    }

    const url = URL.createObjectURL(file);

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);

    return file;
  }

  private static getLocation(building: msevtmgt_building | undefined): string {
    let address = '';
    if (building?.msevtmgt_name) {
      address += building.msevtmgt_name + ' ';
    }
    if (building?.msevtmgt_addressline1) {
      if (building?.msevtmgt_name) {
        address += ', ';
      }
      address += building.msevtmgt_addressline1;
    }
    if (building?.msevtmgt_addressline2) {
      if (building?.msevtmgt_addressline1) {
        address += ' ';
      }
      address += building.msevtmgt_addressline2;
    }
    if (building?.msevtmgt_addressline3) {
      if (building?.msevtmgt_addressline1 || building?.msevtmgt_addressline2) {
        address += ' ';
      }
      address += building.msevtmgt_addressline3;
    }
    if (building?.msevtmgt_city) {
      if (
        building?.msevtmgt_addressline1 ||
        building?.msevtmgt_addressline2 ||
        building?.msevtmgt_addressline3
      ) {
        address += ', ';
      }
      if (building?.msevtmgt_postalcode) {
        address += building?.msevtmgt_postalcode + ' ';
      }
      address += building?.msevtmgt_city;
    }
    return address;
  }
}
