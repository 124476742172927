import { crmikpk_participantstatus } from '../models/crmikpk_participantstatus';
import { msevtmgt_event } from '../models/msevtmgt_event';

/**
 * Prefixes string with "0" (zero)
 * @param target chars to be prefixed
 * @returns prefixed string
 */
const pad = (target: number | string): string => {
  return `${target}`.padStart(2, '0');
};

/**
 * Checks if date is valid
 * @param target date to be checked
 * @returns true if date is valid
 */
const isValidDate = (target: Date | undefined | null) =>{
  if(undefined === target || null === target){
    return false;
  }
  try{
    target = convertToDate(target);
    return target instanceof Date && !isNaN(target.valueOf());
  }
  catch(e){
    return false
  }
}

/**
 * Tests whether date is valid
 * @param target date object to be checked
 * @returns true if date is valid and not 01.01.0001
 */
const isValidTimestamp = (target: Date | null | undefined): boolean => {
  return (
    undefined !== target &&
    null !== target &&
    new Date(target).getTime() > new Date('0001-01-01T00:00:00').getTime()
  );
};

/**
 * Checks for some invalid dates
 * @param args dates to be checked
 * @returns true if one date argument is not valid
 */
const checkDates = (...args: Date[]) => {
  return args.some((date) => !isValidDate(date));
};

/**
 * Checks for same dates
 * @param date1 first date
 * @param date2 second date
 * @returns true if both dates have the same date
 * @example 28.02.2023 14:00:00 and 28.02.2023 08:00:00 equals true
 */
const isSameDate = (
  date1: Date,
  date2: Date,
  local: boolean = false
): boolean => {
  if (local) {
    return (
      date1.getUTCDate() === date2.getUTCDate() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCFullYear() === date2.getUTCFullYear()
    );
  }
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

/**
 * Formats range in a readable format
 * @param start start date of range
 * @param end end date of range
 * @param delimiter char between dates
 * @returns range of dates in a readable format
 */
const formatRange = (
  start: Date,
  end: Date,
  isLocale: boolean = false,
  delimiter: string = 'bis',
  numeric: boolean = true
): string => {
  if (checkDates(start, end)) {
    throw new Error(
      `Some dates are not valid: ${JSON.stringify({ start, end })}`
    );
  }

  if (start.getTime() > end.getTime()) {
    throw new Error(
      `Start date cannot be after end date ${JSON.stringify({ start, end })}`
    );
  }

  let endDate = `${pad(isLocale ? end.getUTCDate() : end.getDate())}.`;
  endDate += numeric
    ? `${pad(isLocale ? end.getUTCMonth() + 1 : end.getMonth() + 1)}.`
    : ` ${
        isLocale
          ? end.toLocaleDateString('default', { month: 'short' })
          : end.toLocaleDateString('de-de', { month: 'short' })
      }. `;
  endDate += pad(isLocale ? end.getUTCFullYear() : end.getFullYear());

  if (isSameDate(start, end, isLocale)) return endDate;

  let startDate = `${pad(isLocale ? start.getUTCDate() : start.getDate())}.`;
  const isCurrentMonth =
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear();

  if (!isCurrentMonth) {
    startDate += numeric
      ? pad(isLocale ? start.getUTCMonth() + 1 : start.getMonth() + 1) + '.'
      : ` ${
          isLocale
            ? start.toLocaleDateString('default', { month: 'short' })
            : start.toLocaleDateString('de-de', { month: 'short' })
        }. `;
  }

  const isCurrentYear = start.getFullYear() === end.getFullYear();
  if (!isCurrentYear) startDate += `${start.getFullYear()}`;

  return `${startDate} ${delimiter} ${endDate}`;
};

const formatRangeTime = (
  start: Date,
  end: Date,
  isLocale: boolean = false,
  delimiter: string = 'bis',
  numeric: boolean = true,
  showDate: boolean = true
): string => {
  const formattedStartTime = isLocale
    ? `${start.getUTCHours()}`
    : `${start.getHours()}`;
  let formattedEndTime = isLocale
    ? `${end.getUTCHours()}`
    : `${end.getHours()}`;
  let dateString = '';
  if (showDate) {
    const formattedDate = format(start, isLocale, numeric);
    dateString = `${formattedDate} | `;
    if (!isSameDate(start, end, isLocale)) {
      formattedEndTime = ` Uhr ${delimiter} ${format(end, isLocale, numeric)} | ${formattedEndTime}`;
      delimiter = ""
    }
  }
  
  return `${dateString}${formattedStartTime}${delimiter}${formattedEndTime} Uhr`;
};

/**
 * Formats date
 * @param target date to be formatted
 * @returns date formatted as readable date
 */
const format = (
  target: Date | number | undefined,
  isLocale: boolean = false,
  numeric: boolean = true,
  monthFormatShort: boolean = true
): string => {
  if(undefined === target){
    return '';
  }
  if (typeof target === 'number') target = new Date(target);

  if (checkDates(target)) {
    throw new Error(`Date is not valid: ${JSON.stringify({ target })}`);
  }
  let formattedDate = `${pad(
    isLocale ? target.getUTCDate() : target.getDate()
  )}.`;
  if (numeric) {
    formattedDate += pad(
      isLocale ? target.getUTCMonth() + 1 : target.getMonth() + 1
    );
  } else {
    formattedDate +=
      ' ' +
      (isLocale
        ? target.toLocaleString('default', {
            month: monthFormatShort ? 'short' : 'long',
          })
        : target.toLocaleString('de-de', {
            month: monthFormatShort ? 'short' : 'long',
          }));
    if (monthFormatShort) {
      formattedDate += '.';
    }
  }

  if (
    (isLocale && target.getUTCFullYear() !== new Date().getFullYear()) ||
    (!isLocale && target.getFullYear() !== new Date().getFullYear())
  ) {
    formattedDate =
      formattedDate +
      (numeric ? '.' : ' ') +
      (isLocale ? target.getUTCFullYear() : target.getFullYear());
  }

  return formattedDate;
};

const formatTime = (
  target: Date | number,
  isLocale: boolean = false
): string => {
  if (typeof target === 'number') target = new Date(target);

  if (checkDates(target)) {
    throw new Error(`Date is not valid: ${JSON.stringify({ target })}`);
  }
  const formattedDate = `${
    isLocale ? target.getUTCHours() : target.getHours()
  }:${pad(isLocale ? target.getUTCMinutes() : target.getMinutes())} Uhr`;
  return formattedDate;
};

/**
 * Gets date from childevent or parent's child events
 * @param participantstatus participantstatus to get date from
 * @param useEnddate true, to return end or max date. false to return start or min date
 * @returns date from childevent or parent's child events
 */
const getChildEventsDate = (
  participantstatus?: crmikpk_participantstatus,
  useEnddate: boolean = false
): Date | null => {
  //get date from child event
  if (!participantstatus?.crmikpk_eventid?.crmikpk_isroadshowbool) {
    if (
      useEnddate &&
      participantstatus?.crmikpk_child_event_id?.msevtmgt_eventenddate
    ) {
      return convertToDate(
        participantstatus.crmikpk_child_event_id.msevtmgt_eventenddate
      );
    } else if (
      !useEnddate &&
      participantstatus?.crmikpk_child_event_id?.msevtmgt_eventstartdate
    ) {
      return convertToDate(
        participantstatus?.crmikpk_child_event_id?.msevtmgt_eventstartdate
      );
    }
  }

  //get date from all children of a roadshow event
  const event: msevtmgt_event | undefined = participantstatus?.crmikpk_eventid;
  if (!event || !event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid) {
    return null;
  }

  //select date of all children
  const dates = event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid
    .map((e) =>
      convertToDate(
        useEnddate ? e.msevtmgt_eventenddate : e.msevtmgt_eventstartdate
      )
    )
    .filter((e) => e !== null);
  if (dates.length === 0) return null;

  return dates.sort((a, b) => a!.getTime() - b!.getTime())[
    useEnddate ? dates.length - 1 : 0
  ];
};

const getRegistrationEndDate = (
  participantstatus: crmikpk_participantstatus | undefined
): Date | null => {
  if (
    participantstatus?.crmikpk_child_event_id
      ?.msevtmgt_stopwebsiteregistrationson
  ) {
    return convertToDate(participantstatus.crmikpk_child_event_id
      .msevtmgt_stopwebsiteregistrationson, true);
  }

  const event:
    | (msevtmgt_event & {
        crmikpk_msevtmgt_event_msevtmgt_event_parenteventid?: msevtmgt_event[];
      })
    | undefined = participantstatus?.crmikpk_eventid;
  if (
    !event ||
    !event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid ||
    0 === event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid.length
  ) {
    return null;
  }
  const dates: (Date | null)[] | undefined =
    event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid?.map((e) =>
      convertToDate(e.msevtmgt_stopwebsiteregistrationson, true)
    );
  if (!dates || 0 === dates.length) {
    return null;
  }
  return dates.sort()[0];
};

const getRegistrationEventsDate = (participantstatus:crmikpk_participantstatus|undefined):Date|null=>{
  if(participantstatus?.crmikpk_child_event_id?.msevtmgt_eventenddate){
    return convertToDate(
      participantstatus.crmikpk_child_event_id.msevtmgt_eventenddate
    );
  }
  return getChildEventsDate(participantstatus, true);
};

const convertToDate = (date: Date | undefined, toLocal:boolean = false): Date | null => {
  if (undefined !== date && DateUtility.isValidTimestamp(date)) {
    if(!toLocal){
      return new Date(date);
    }
    date = new Date(date);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return new Date(year,month, day, hours, minutes);
  }
  return null;
};

/**
 * Selects earliest and latest date
 * @param events events where dates (start) should be extracted
 * @returns earliest and latest date of given start
 */
const getChildEventDatesFromEvent = (
  events: msevtmgt_event[]
): [Date, Date] => {
  const dates: Date[] = [];
  events.forEach((event: msevtmgt_event) => {
    const start = convertToDate(event.msevtmgt_eventstartdate);
    start && dates.push(start);
  });

  dates.sort((a, b) => a.getTime() - b.getTime());
  return [dates[0], dates[dates.length - 1]];
};

/**
 * Formatter for date spans
 */
export const DateUtility = {
  convertToDate,
  formatRange,
  formatRangeTime,
  format,
  formatTime,
  isSameDate,
  isValidDate,
  isValidTimestamp,
  getChildEventsDate,
  getChildEventDatesFromEvent,
  getRegistrationEndDate,
  getRegistrationEventsDate,
  pad,
};
