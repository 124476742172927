import { contact } from './contact';
import { msevtmgt_event } from './msevtmgt_event';

/**
 * Generated with https://www.xrmtoolbox.com/plugins/EntityTSCreator/
 */
export interface crmikpk_participantstatus {
  /**
   * This Property is added manually
   */
  crmikpk_child_event_id?: msevtmgt_event;
  crmikpk_eventid?: msevtmgt_event;

  // crmikpk_child_event_id?: string;
  crmikpk_child_event_idname?: string;
  crmikpk_currentstatecode?: crmikpk_currentstatecodeEnum;
  crmikpk_currentstatecodename?: crmikpk_currentstatecodeEnum;
  crmikpk_currentstatusdetailcode?: crmikpk_currentstatusdetailcodeEnum;
  crmikpk_currentstatusdetailcodename?: crmikpk_currentstatusdetailcodeEnum;
  // crmikpk_eventid?: string;
  crmikpk_eventidname?: string;
  crmikpk_iscoordinatorbool?: boolean;
  crmikpk_iscoordinatorboolname?: boolean;
  crmikpk_name?: string;
  crmikpk_participantaccountid?: string;
  crmikpk_participantaccountidname?: string;
  crmikpk_participantaccountidyominame?: string;
  crmikpk_participantid?: contact | undefined;
  crmikpk_participantidname?: string;
  crmikpk_participantidyominame?: string;
  crmikpk_participantstatusid: string;
  crmikpk_propertyentryid?: string;
  crmikpk_propertyentryidname?: string;
  crmikpk_srccreatedby?: string;
  crmikpk_srccreatedon?: Date;
  crmikpk_srccreatedsys?: string;
  crmikpk_srcmodifiedby?: string;
  crmikpk_srcmodifiedon?: Date;
  crmikpk_srcmodifiedsys?: string;
  crmikpk_statuscodechange?: Date;
  crmikpk_statusdescription?: string;
  crmikpk_supplysourceid?: string;
  crmikpk_supplysourceidname?: string;
  crmikpk_uukey?: string;
}
export enum crmikpk_currentstatecodeEnum {
  Akzeptiert = 136470001,
  Nichtgenehmigt = 136470002,
  Eingeladen = 136470004,
  Nachzügler = 136470003,
  Abgewählt = 136470005,
}
export enum crmikpk_currentstatusdetailcodeEnum {
  KeineRückmeldung = 136470000,
  Registriert = 136470001,
  Check_In = 136470002,
  Storniert_Abgesagt = 136470003,
  Warteliste = 136470004,
}
