<div class="backdrop" (click)="close()"></div>

<div class="modal">
  <sd-4-0-0-button
    title="Schließen"
    variant="tertiary"
    type="button"
    class="close-btn"
    (click)="close()"
  >
    <sd-4-0-0-icon
      name="system/close"
      library="default"
      color="primary"
      class="close-icon"
    ></sd-4-0-0-icon>
  </sd-4-0-0-button>

  <div class="meta">
    <img [src]="getSpeakerImage(speaker!)" />

    <h3 class="sd-1-0-1-headline sd-1-0-1-headline--size-lg heading">
      {{ speaker?.msevtmgt_title }} {{ speaker?.msevtmgt_name }}
    </h3>
    <p class="sd-1-0-1-paragraph job">
      {{ getMeta(speaker) }}
    </p>
    <iko-va-social-media-links [speaker]="speaker"></iko-va-social-media-links>
  </div>
  <p class="about sd-1-0-1-paragraph">{{ speaker?.msevtmgt_about }}</p>
</div>
