import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DateUtility } from 'src/app/helpers/DateUtility';
import { getChildEventCities, isRoadshow } from 'src/app/helpers/EventHelpers';
import { msevtmgt_building } from 'src/app/models/msevtmgt_building';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';

@Component({
  selector: 'iko-va-event-meta-information',
  templateUrl: './event-meta-information.component.html',
  styleUrls: ['./event-meta-information.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventMetaInformationComponent implements OnInit {
  @Input() event?: msevtmgt_event;
  private start?: Date;
  private end?: Date;
  private isRoadshow: boolean = false;
  private childEvents: msevtmgt_event[] | undefined;

  ngOnInit(): void {
    if (!this.event) return;

    this.isRoadshow = isRoadshow(this.event);
    this.childEvents = this.getChildren();
    if (this.isRoadshow && this.childEvents) {
      const dates = DateUtility.getChildEventDatesFromEvent(this.childEvents);
      this.start = dates[0];
      this.end = dates[1];
    }
  }

  getFormattedDate(): string {
    if (!this.event) return '';

    if (this.isRoadshow && this.childEvents && this.start && this.end) {
      return `${DateUtility.formatRange(
        this.start,
        this.end,
        true,
        'bis',
        false
      )}, mehrere Termine`;
    } else {
      if (
        this.event.msevtmgt_eventstartdate &&
        this.event.msevtmgt_eventenddate
      ) {
        const start = DateUtility.convertToDate(
          this.event.msevtmgt_eventstartdate
        );
        const end = DateUtility.convertToDate(this.event.msevtmgt_eventenddate);

        if (start === null || end === null) return '';
        return DateUtility.formatRangeTime(start, end, true, '-', false);
      }
      return '';
    }
  }

  getFormattedCities(): string {
    if (!this.event) return '';

    if (this.isRoadshow && this.childEvents) {
      const distinctCities = new Set(getChildEventCities(this.childEvents));
      return [...distinctCities].join(', ');
    } else if (this.event.msevtmgt_building) {
      return this.getFormattedAddress(this.event.msevtmgt_building);
    }
    return "Keine Standort(e)";
  }

  getFormattedAddress(building: msevtmgt_building | undefined): string {
    let address = '';
    if (building?.msevtmgt_name) {
      address += building.msevtmgt_name;
    }
    if (building?.msevtmgt_addressline1) {
      address += '<br/>' + building.msevtmgt_addressline1;
    }
    if (building?.msevtmgt_addressline2) {
      address += '<br/>' + building.msevtmgt_addressline2;
    }
    if (building?.msevtmgt_addressline3) {
      address += '<br/>' + building.msevtmgt_addressline3;
    }
    if (building?.msevtmgt_city) {
      if (
        building.msevtmgt_name || 
        building?.msevtmgt_addressline1 ||
        building?.msevtmgt_addressline2 ||
        building?.msevtmgt_addressline3
      ) {
        address += '<br/>';
      }
      if (building?.msevtmgt_postalcode) {
        address += building?.msevtmgt_postalcode + ' ';
      }
      address += building?.msevtmgt_city;
    }

    return address;
  }

  getChildren() {
    return (
      this.event?.crmikpk_parenteventid
        ?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid ??
      this.event?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid
    );
  }
}
