import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateUtility } from 'src/app/helpers/DateUtility';
import { getEventName } from 'src/app/helpers/EventHelpers';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';

@Component({
  selector: 'iko-va-event-selector',
  templateUrl: './event-selector.component.html',
  styleUrls: ['./event-selector.component.css'],
})
export class EventselectorComponent {
  @Input() childEvents: msevtmgt_event[] | undefined;
  @Input() selectedChildEvent: msevtmgt_event | null | undefined;
  @Output() eventChange: EventEmitter<string> = new EventEmitter();

  public onChipClick(selectedEventId: string) {
    this.eventChange.emit(selectedEventId);
  }

  public getTitle(child: msevtmgt_event) {
    let title = child.msevtmgt_building?.msevtmgt_city??'';
    const startDate = DateUtility.convertToDate(child.msevtmgt_eventstartdate);
    if (
      this.selectedChildEvent?.msevtmgt_eventid == child.msevtmgt_eventid &&
      title != '' &&
      DateUtility.isValidDate(startDate)
    ) {
      title += ', ' +EventselectorComponent.formatDate(startDate);
    } else if (title == '') {
      title = EventselectorComponent.formatDate(startDate)
    }
    if (title == ''){
      title = getEventName(child);
    }
    return title;
  }
  private static formatDate(date:Date|null):string{
    if(DateUtility.isValidDate(date)){
      return date!.toLocaleString('de-de', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
    }
    return '';
  }
}
