import { environment } from 'src/environments/environment';

import {
  crmikpk_currentstatusdetailcodeEnum,
  crmikpk_participantstatus,
} from '../models/crmikpk_participantstatus';
import { msevtmgt_event } from '../models/msevtmgt_event';
import { Filter } from '../types/Filter';

import { DateUtility } from './DateUtility';
import { extractContent } from './HTMLSanitizer';

const isAccepted = (status: crmikpk_participantstatus) => {
  return (
    status.crmikpk_currentstatusdetailcode ===
      crmikpk_currentstatusdetailcodeEnum.Check_In ||
    status.crmikpk_currentstatusdetailcode ===
      crmikpk_currentstatusdetailcodeEnum.Registriert
  );
};

export const defaultFilterFunction = (items: crmikpk_participantstatus[]) => {
  console.warn('Filter not implemented');
  return items?.filter(() => {
    return false;
  });
};

export const defaultFilters: Filter<crmikpk_participantstatus>[] = [
  {
    key: 'CurrentEvents',
    label: 'Aktuelle Veranstaltungen',
    filterEvents: (items: crmikpk_participantstatus[]) => {
      return items.filter((e) => {
        /* Using the end date instead of the start date ensures that events
         already in progress are also visible.*/

        const childEventDate = DateUtility.getRegistrationEventsDate(e);
        return (
          childEventDate && childEventDate.getTime() >= new Date().getTime()
        );
      });
    },
  },
  {
    key: 'Confirmed',
    label: 'Zugesagt',
    filterEvents: (items: crmikpk_participantstatus[]) => {
      return items.filter((e) => {
        const childEventDate = DateUtility.getRegistrationEventsDate(e);

        return (
          childEventDate &&
          childEventDate.getTime() >= new Date().getTime() &&
          isAccepted(e)
        );
      });
    },
  },
  {
    key: 'Bookmarked',
    label: 'Gemerkt',
    filterEvents: defaultFilterFunction,
    isHidden: true,
  },
  {
    key: 'Participated',
    label: 'Teilgenommen',
    usePastEvents: true,
    filterEvents: (items: crmikpk_participantstatus[]) =>
      items.filter((e) => {
        const childEventEndDate = DateUtility.getRegistrationEventsDate(e);

        return (
          childEventEndDate &&
          childEventEndDate.getTime() < new Date().getTime() &&
          isAccepted(e)
        );
      }),
  },
  {
    key: 'Gone',
    label: 'Vergangen',
    usePastEvents: true,
    filterEvents: (items: crmikpk_participantstatus[]) =>
      items.filter((e) => {
        /* Using the end date instead of the start date ensures that events
          still in progress are NOT visible because it is still in progress */

        const childEventEndDate = DateUtility.getRegistrationEventsDate(e);
        return (
          childEventEndDate &&
          childEventEndDate.getTime() < new Date().getTime()
        );
      }),
  },
].filter((filter) => !filter.isHidden);

export const sortEventsByFilter = (
  data: crmikpk_participantstatus[],
  filter?: Filter<crmikpk_participantstatus>
): crmikpk_participantstatus[] => {
  return data.sort((a, b) => {
    const aDate = DateUtility.getRegistrationEventsDate(a);
    const bDate = DateUtility.getRegistrationEventsDate(b);

    if (filter?.key == 'Gone' || filter?.key == 'Participated')
      if (null == bDate) {
        return -1;
      } else if (null == aDate) {
        return 1;
      } else {
        return (
          bDate.getTime() - aDate.getTime() // sort date descending (latest on top)
        );
      }
    if (null == bDate) {
      return 1;
    } else if (null == aDate) {
      return -1;
    } else {
      return aDate.getTime() - bDate.getTime(); // sort date ascending (next on top)
    }
  });
};

export const  buildEventDetailURL = (
  id: string,
  baseUrl: string,
  isParentEvent: boolean,
  childEventId: string | undefined
): URL => {
  const url = new URL(baseUrl + `/veranstaltungen`);
  url.searchParams.append('p', 'details');
  url.searchParams.append('id', id);

  if (isParentEvent) {
    url.searchParams.append('isParent', '1');
    if (childEventId) {
      url.searchParams.append('cid', childEventId);
    }
  }
  return url;
}

/**
 * Retrieves a list of all cities where given events are planned
 * @param events events where all cities should be extracted
 * @returns all cities where events take place
 */
export const getChildEventCities = (events: msevtmgt_event[]): string[] => {
  const result: string[] = [];

  for (const event of events) {
    event.msevtmgt_building?.msevtmgt_city &&
      result.push(event.msevtmgt_building?.msevtmgt_city);
  }

  return result;
};

/**
 * Checks whether given event is roadshow based on amount of child events
 * @param event event to be checked
 * @returns true if event has multiple child events
 */
export const isRoadshow = (event: msevtmgt_event): boolean => {
  if (event.crmikpk_parenteventid) {
    const parentEvent = event.crmikpk_parenteventid;
    event = parentEvent;
  }

  const childEvents = event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid;
  return childEvents !== undefined && childEvents.length > 1;
};

export const isInPast = (event: msevtmgt_event): boolean => {
  if(!DateUtility.isValidDate(event.msevtmgt_eventenddate)){
    return true;
  }
  const eventDate = DateUtility.convertToDate(event.msevtmgt_eventenddate!, true)!;
  const now = new Date();
  return now > eventDate;
};

export const isInPastDate = (date: Date|null|undefined): boolean => {
  if(!DateUtility.isValidDate(date)){
    return true;
  }
  const eventDate = DateUtility.convertToDate(date!, true)!;
  const now =  new Date();
  return now > eventDate;
};

export const isParentEventStatus = (participantStatus: crmikpk_participantstatus | null | undefined, eventId:string | null | undefined): boolean => {
  return !participantStatus?.crmikpk_child_event_id || (participantStatus?.crmikpk_eventid?.crmikpk_isroadshowbool && eventId === participantStatus?.crmikpk_eventid?.msevtmgt_eventid)|| 
  (!participantStatus?.crmikpk_eventid?.crmikpk_isroadshowbool &&(
    !participantStatus?.crmikpk_child_event_id ||
    participantStatus?.crmikpk_eventid?.msevtmgt_eventid ===
      participantStatus?.crmikpk_child_event_id.msevtmgt_eventid));
}

/**
 * Tries to get the first child of given event
 * @param status to be checked
 * @returns first event child or undefined
 */
export const tryGetFirstEventChild = (
  status: crmikpk_participantstatus
): msevtmgt_event | undefined => {
  return (
    (status?.crmikpk_eventid &&
      status?.crmikpk_eventid
        .crmikpk_msevtmgt_event_msevtmgt_event_parenteventid &&
      status?.crmikpk_eventid.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid
        .length > 0 &&
      status?.crmikpk_eventid
        .crmikpk_msevtmgt_event_msevtmgt_event_parenteventid[0]) ||
    undefined
  );
};

export const getLocations = (
  participantStatus: crmikpk_participantstatus,
  isRoadshow: boolean = false
): string[] => {
  const childLocations: string[] = [];
  if (
    (isRoadshow || !participantStatus.crmikpk_child_event_id) &&
    participantStatus.crmikpk_eventid &&
    participantStatus.crmikpk_eventid
      ?.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid
  ) {
    for (const event of participantStatus.crmikpk_eventid
      .crmikpk_msevtmgt_event_msevtmgt_event_parenteventid) {
      if (event.msevtmgt_building?.msevtmgt_city) {
        childLocations.push(event.msevtmgt_building.msevtmgt_city);
        
      }
    }
  } else if (
    participantStatus.crmikpk_child_event_id &&
    participantStatus.crmikpk_child_event_id?.msevtmgt_building?.msevtmgt_city
  ) {
    childLocations.push(
      participantStatus.crmikpk_child_event_id?.msevtmgt_building?.msevtmgt_city
    );
  }
  return childLocations;
};

export const getFormattedDateSpan = (
  participantStatus: crmikpk_participantstatus
) => {
  const startDate = DateUtility.getChildEventsDate(participantStatus);
  const endDate = DateUtility.getChildEventsDate(participantStatus, true);

  if (
    DateUtility.isValidTimestamp(startDate) &&
    DateUtility.isValidTimestamp(endDate)
  ) {
    try {
      return DateUtility.formatRange(<Date>startDate, <Date>endDate, true);
    } catch (error) {
      console.error(error);
    }
  } else if (DateUtility.isValidTimestamp(endDate)) {
    try {
      return DateUtility.format(<Date>endDate, true);
    } catch (error) {
      console.error(error);
    }
  }
  return 'Kein Datum';
};

export const getImage = (participantStatus: crmikpk_participantstatus | null | undefined) => {
  if(!participantStatus?.crmikpk_child_event_id && participantStatus?.crmikpk_eventid && !participantStatus.crmikpk_eventid.msevtmgt_eventimage?.msdyncrm_blobcdnuri){
    const childimages = participantStatus.crmikpk_eventid
      .crmikpk_msevtmgt_event_msevtmgt_event_parenteventid?.filter(c=>c.msevtmgt_eventimage?.msdyncrm_blobcdnuri).map(c=>c.msevtmgt_eventimage?.msdyncrm_blobcdnuri);
        if(childimages && 0 < childimages.length){
          return childimages[0];
        }
      }
  return (
    participantStatus?.crmikpk_child_event_id?.msevtmgt_eventimage
      ?.msdyncrm_blobcdnuri ||
    participantStatus?.crmikpk_eventid?.msevtmgt_eventimage
      ?.msdyncrm_blobcdnuri ||
    environment.imagesEndpoint + '/default-event-image.jpg'
  );
};

export const  getImageByEvent =(event: msevtmgt_event | null | undefined) => {
  return (
    event?.msevtmgt_eventimage?.msdyncrm_blobcdnuri ||
    event?.crmikpk_parenteventid?.msevtmgt_eventimage?.msdyncrm_blobcdnuri ||
    environment.imagesEndpoint + '/default-event-image.jpg'
  );
};

export const getFormattedEventName = (
  participantStatus: crmikpk_participantstatus
) => {
  const maxChars = 50;
  let eventName =
    participantStatus?.crmikpk_child_event_id
      ?.crmikpk_headingeventdescriptionportal ||
    participantStatus?.crmikpk_eventid?.crmikpk_headingeventdescriptionportal ||
    participantStatus?.crmikpk_child_event_id?.msevtmgt_name ||
    participantStatus?.crmikpk_eventid?.msevtmgt_name;
  eventName = extractContent(eventName);
   if (eventName && eventName.length > maxChars) {
    const trimmedName = `${eventName.slice(0, maxChars)}`;
    const lastSpace = trimmedName.lastIndexOf(' ');
    if(lastSpace > (0.8*maxChars)){
      return `${trimmedName.slice(0, lastSpace)}...`;
    }
     return `${trimmedName}...`;
   }
  return eventName;
};

export const getEventName = (event?: msevtmgt_event):string => {
  let eventName =
    event?.crmikpk_headingeventdescriptionportal || event?.msevtmgt_name;
  eventName = extractContent(eventName);

  return eventName;
};

export const getTrimmedDescription = (
  participantStatus: crmikpk_participantstatus
) => {
  const maxChars = 120;
  const description =
    participantStatus?.crmikpk_child_event_id
      ?.crmikpk_subheadingeventdescriptionportal ||
    participantStatus?.crmikpk_eventid
      ?.crmikpk_subheadingeventdescriptionportal ||
    tryGetFirstEventChild(participantStatus)?.crmikpk_subheadingeventdescriptionportal ||
    '';

  return extractContent(description).slice(0, maxChars);
};

export const getStatus = (participantStatus: crmikpk_participantstatus) => {
  if (
    participantStatus.crmikpk_currentstatusdetailcode ===
      crmikpk_currentstatusdetailcodeEnum.Registriert ||
    participantStatus.crmikpk_currentstatusdetailcode ===
      crmikpk_currentstatusdetailcodeEnum.Check_In
  ) {
    return 'Zugesagt';
  }
  const deadline = DateUtility.getRegistrationEndDate(participantStatus);
  if (
    null !== deadline &&
    DateUtility.isValidDate(deadline) &&
    deadline.getTime() >= new Date().getTime()
  ) {
    return `Anmelden bis ${DateUtility.format(deadline, true)}`;
  }
  return undefined;
};

export const getStatusClass = (
  participantStatus: crmikpk_participantstatus
): string => {
  if (
    participantStatus.crmikpk_currentstatusdetailcode ===
      crmikpk_currentstatusdetailcodeEnum.Registriert ||
    participantStatus.crmikpk_currentstatusdetailcode ===
      crmikpk_currentstatusdetailcodeEnum.Check_In
  ) {
    return 'success';
  }
  const deadline = DateUtility.getRegistrationEndDate(participantStatus);

  if (
    null !== deadline &&
    DateUtility.isValidDate(deadline) &&
    DateUtility.isSameDate(
      new Date(),
      deadline
    )
  ) {
    return 'lastMinute';
  }

  if (
    null !== deadline &&
    DateUtility.isValidDate(deadline) &&
    deadline.getTime() > new Date().getTime()
  ) {
    return 'secondary';
  }  
  return 'failed';
};
