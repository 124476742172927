import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  ViewChild,
  ViewChildren} from '@angular/core';
import { msevtmgt_eventcustomregistrationfield } from 'src/app/models/msevtmgt_customregistrationfield';
import { msevtmgt_signup } from 'src/app/models/msevtmgt_signup';
import { custom_field_response, registration_data, registration_result } from 'src/app/models/registration';
import { CacheService } from 'src/app/services/Cache.service';
import { RegistrationService } from 'src/app/services/Registration.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

import { ModalHelper } from '../../helpers/ModalHelper';
import { RegistrationCustomFieldComponent } from '../registration-custom-field/registration-custom-field.component';

type OnRegistrationSuccessful = (selectedEventId: string) => void;

@Component({
  selector: 'iko-va-signup-form-modal',
  templateUrl: './signup-form-modal.component.html',
  styleUrls: ['./signup-form-modal.component.css'],
})
export class SignupFormModalComponent extends ModalHelper {
  @HostBinding('class.isOpen') isModalOpen = false;

  @ViewChild("elem_first_name") firstNameElement?: ElementRef;
  @ViewChild("elem_last_name") lastNameElement?: ElementRef;
  @ViewChild("elem_title") titleElement?: ElementRef;
  @ViewChild("elem_salutation") salutationElement?: ElementRef;
  @ViewChild("elem_agb_prim") elem_cb_prim?: ElementRef;
  @ViewChild("elem_agb_ex") elem_cb_ex?: ElementRef;
  @ViewChildren('custom_field_component') custom_field_components?: QueryList<RegistrationCustomFieldComponent>;

  @Input()
  registrationSucessful?: OnRegistrationSuccessful;

  validation_errors: string[] | null;
  in_progress: boolean = false;
  signUpToEvent?: msevtmgt_signup;
  registrationFields?: custom_field_response[] | null;

  constructor(
    private registrationService: RegistrationService,
    private trackingService: TrackingService
  ) {
    super({ enableCloseOnEscape: true });
    this.closeOnEscapeFunction = () => {
      this.isModalOpen = false;
    };
    this.validation_errors = null;
  }

  open(signUpToEvent: msevtmgt_signup) {
    this.validation_errors = null;
    this.in_progress = false;
    this.signUpToEvent = signUpToEvent;

    this.isModalOpen = true;
    this._open();
    this.fetchRegistrationFields();
  }

  fetchRegistrationFields() {
    try {
      if (this.signUpToEvent != null) {
        this.registrationService
          .getCustomRegistrationFields(this.signUpToEvent!.event_id)
          .subscribe({
            next: (registrationfields: msevtmgt_eventcustomregistrationfield[] | null) => {
              if (registrationfields == null) {
                this.registrationFields = null;
              }
              else {
                this.registrationFields = registrationfields
                  .sort(fld => fld.msevtmgt_order)
                  .map<custom_field_response>(fld => ({ custom_registration_field: fld, event_custom_registration_field_id: fld.msevtmgt_eventcustomregistrationfieldid }));
              }
            },
            error: (err) => {
              console.error(err);
              this.registrationFields = null;
            },
          })
      }
    }
    catch (err) {
      console.error(err);
      this.registrationFields = null;
    }
  }

  startRegisterEvent(): Promise<registration_result> {
    return new Promise<registration_result>((resolve) => {
      try {
        if (this.signUpToEvent != null) {
          this.registrationService
            .registerEvent(this.signUpToEvent!.event_id, this.signUpToEvent!.user!.contactid!)
            .subscribe({
              next: (data: any) => {//eslint-disable-line @typescript-eslint/no-explicit-any
                if (data != null) {
                  if (data.error?.errorMessage != null) {
                    resolve({ success: false, error_message: data.error?.errorMessage });
                  }
                  else {
                    const eventRegistrationId = data.msevtmgt_eventregistrationid;
                    this.registrationFields?.filter(fld => fld.response_value != null).forEach(fld => {
                      this.registrationService.setCustomRegistrationFields(
                        eventRegistrationId,
                        fld.custom_registration_field.msevtmgt_customregistrationfield.msevtmgt_customregistrationfieldid,
                        fld.response_value!)
                        .subscribe( {
                          next: () => {
                          }})
                        });

                    resolve({ success: true });
                  }
                }
                else {
                  const result: registration_result = { success: false, error_message: "Fehler beim Anmeldeversuch. Bitte probieren sie es später nochmals." };
                  resolve(result);
                }
              },
              error: (err) => {
                console.error(err);
                resolve({ success: false, error_message: "Fehler beim Anmeldeversuch. Bitte probieren sie es später nochmals." })
              },
            });
        }
      }
      catch (err) {
        console.error(err);
        resolve({ success: false, error_message: "Fehler beim Anmeldeversuch. Bitte probieren sie es später nochmals." })
      }
    });
  }

  async onSubmit() {
    this.signUpToEvent!.registration!.salutation = this.salutationElement?.nativeElement.value;
    this.signUpToEvent!.registration!.title = this.titleElement?.nativeElement.value;
    this.signUpToEvent!.registration!.first_name = this.firstNameElement?.nativeElement.value;
    this.signUpToEvent!.registration!.last_name = this.lastNameElement?.nativeElement.value;
    this.signUpToEvent!.registration!.terms_of_use_prim = this.elem_cb_prim?.nativeElement.checked;
    this.signUpToEvent!.registration!.terms_of_use_ex = this.elem_cb_ex?.nativeElement.checked;

    this.custom_field_components?.forEach(reg => {
      reg.regField!.response_value = reg.getFieldValue();
    });
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Click 'Anmeldung absenden'`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementType: 'Button',
        elementLabel: 'Anmeldung absenden',
        target: 'registration-success-modal',
        customECommerceParameter: {
          48: 'sd-2-11-0-button',
          49: 'sd-2-11-0',
          50: environment.app.NAME,
        },
      },
    });
    await this.doRegisterEvent();
  }

  async doRegisterEvent() {
    if (this.validate()) {
      const registrationData: registration_data = {
        event_id: this.signUpToEvent!.event_id!,
        contact_id: this.signUpToEvent!.user.contactid!,
        registration: this.signUpToEvent!.registration!,
      };

      if (this.registrationFields != null)
      {
        registrationData.custom_fields = this.registrationFields;
      }

      this.in_progress = true;

      this.startRegisterEvent().then((result) => {
        this.in_progress = false;
        if (result.success) {
          if (this.registrationSucessful != null) {
            this.registrationSucessful(registrationData.event_id);
          }
          CacheService.removeEvents(false);
          this.close();
        }
        else {
          const validation_errors: string[] = [];
          validation_errors.push(result.error_message!);
          this.validation_errors = validation_errors;
        }
      });
    }
  }

  validate(): boolean {
    const validation_errors: string[] = [];
    if (!this.signUpToEvent!.registration!.terms_of_use_prim) {
      validation_errors.push("Datenschutzhinweise müssen bestätigt werden.");
    }
    if (!this.signUpToEvent!.registration!.terms_of_use_ex) {
      validation_errors.push("AGB muss bestätigt sein.");
    }

    if (this.registrationFields != null) {
      this.registrationFields.forEach(element => {
        const fldDef = element.custom_registration_field.msevtmgt_customregistrationfield;
        if (fldDef.msevtmgt_isrequired && element.response_value == null)
        {
          validation_errors.push(`${fldDef.crmikpk_title} ist ein Pflichtfeld.`);
        }
      });
    }

    const ok = validation_errors.length == 0;
    if (ok) {
      this.validation_errors = null;
    }
    else {
      this.validation_errors = validation_errors;
    }

    return ok;
  }

  close() {
    this.isModalOpen = false;
    this._close();
  }

  extractContent(html: string | undefined): string {
    const span = document.createElement('span');
    span.innerHTML = html ?? "";
    return span.textContent || span.innerText;
  };
}
