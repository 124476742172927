import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpServiceHelper } from '../interfaces/HttpService';

const splice = (
  target: string,
  index: number,
  deleteCount: number,
  replaceString: string
) => {
  return (
    target.slice(0, index) +
    replaceString +
    target.slice(index + Math.abs(deleteCount))
  );
};

const insertRoute = (url: string, route: string) => {
  const lastSlash = url.indexOf('?');
  return splice(url, lastSlash, 0, route);
};

@Injectable({
  providedIn: 'root',
})
export class HttpRestMockService implements HttpServiceHelper {
  constructor(private httpClient: HttpClient) {
    if (environment.test) {
      environment.crm.apiEndpoint = `/assets/mocks/api/data/`;
    }
    else if (environment.useMockData)
    {
      environment.crm.apiEndpoint = `http://localhost:4200/assets/mocks/api/data/`;
    }
  }

  public get<T>(url: string): Observable<T> {
    let route = 'get.json';
    if(0< url.indexOf('participantstatus/v9.2')&& 0 < url.indexOf('participantid') && 0 < url.indexOf('eventid')){
      route = 'eventdetails_get.json';
    }
    if (url.indexOf('?') !== -1) {
      url = insertRoute(url, `/${route}`);
    } else url = `${url}/${route}`;

    return new Observable((observer) => {
      this.httpClient.get<T>(url).subscribe(observer);
    });
  }

  public post<T>(url: string, body: any): Observable<T> {//eslint-disable-line @typescript-eslint/no-explicit-any
    if (url.indexOf('?') !== -1) {
      url = insertRoute(url, '/post.json');
    } else url = `${url}/post.json`;
if(body){
  console.warn(body);
}
    return new Observable((observer) => {
      this.httpClient.get<T>(url).subscribe(observer);
    });

    // return new Observable((observer) => {
    //   this.httpClient.post<T>(url, body).subscribe(observer);
    // });
  }
}
