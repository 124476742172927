import { Component, Input } from '@angular/core';
import { SpeakerHelper } from 'src/app/helpers/SpeakerHelper';
import { msevtmgt_speaker } from 'src/app/models/msevtmgt_speaker';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'iko-va-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.css'],
})
export class SpeakersComponent {
  @Input() speakers?: msevtmgt_speaker[];

  selectedSpeaker?: msevtmgt_speaker | null = null;

  constructor(private trackingService: TrackingService) {}

  getSpeakerImage(speaker: msevtmgt_speaker): string {
    return SpeakerHelper.getSpeakerImage(speaker);
  }

  getMeta(speaker: msevtmgt_speaker): string {
    return SpeakerHelper.getMetaDetails(speaker);
  }

  readMore(speaker: msevtmgt_speaker) {
    return () => {
      this.trackingService.track({
        eventInfo: {
          eventType: 'Action',
          eventName: `Click 'weiterlesen'`,
        },
        category: {
          area: 'Content',
        },
        attributes: {
          virtualTarget: `${speaker.msevtmgt_title} ${speaker.msevtmgt_name}`,
          elementType: 'Link',
          elementLabel: 'weiterlesen',
          customECommerceParameter: {
            48: 'sd-4-0-0-link',
            49: 'sd-4-0-0',
            50: environment.app.NAME,
          },
        },
      });
      this.selectedSpeaker = speaker;
    };
  }
}
