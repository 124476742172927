<ng-template [ngIf]="loading">
  <div class="center" *ngIf="loading">
    <div style="font-size: 2rem">
      <sd-2-11-0-spinner color="primary"></sd-2-11-0-spinner>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="!loading && !valid">
  <iko-va-container>
    <iko-va-error-message [errorMessage]="error" [action]="this.navigateToParent" [buttonText]="'Zurück zur Übersicht'"></iko-va-error-message>
  </iko-va-container>
</ng-template>

<ng-template [ngIf]="!loading && valid">
  <iko-va-container>
    <iko-va-breadcrumb [event]="event!"></iko-va-breadcrumb>
  </iko-va-container>
  <iko-va-grid type="container">
    <iko-va-grid type="item" [span]="['xl-8', '2xl-8']">
      <table class="sd-2-11-0-table">
        <tbody>
          <tr>
            <td>
              <p
                class="sd-2-11-0-headline sd-2-11-0-headline--size-3xl" style="line-height: 2.4em;"
                [innerHTML]="getHeading()"
              ></p>
            </td>
            <td style="padding-left: 30px; padding-top: 5px">
              <iko-va-tag
                *ngIf="isParticipantRegistered()"
                class="status success"
                >Angemeldet</iko-va-tag
              >
            </td>
          </tr>
        </tbody>
      </table>
    </iko-va-grid>
    <iko-va-grid type="item" [span]="['xl-4', '2xl-4']" class="mt-3"></iko-va-grid>
    <iko-va-grid type="item" [span]="['xl-8', '2xl-8']">
      <div
        class="sd-2-11-0-leadtext sd-2-11-0-leadtext--size-lg"
        *ngIf="event?.crmikpk_subheadingeventdescriptionportal"
        [innerHTML]="
          event?.crmikpk_subheadingeventdescriptionportal | sanitizeHtml
        "
      ></div>

      <div *ngIf="isAllEventsInPast()">
        <sd-2-11-0-notification
          open="" variant="info" toast-stack="top-right" duration="Infinity">
          <div>
            Diese Veranstaltung liegt in der Vergangenheit
          </div>
        </sd-2-11-0-notification>
      </div>

      <div *ngIf="!isRoadshow()">
        <sd-2-11-0-button
          title=" {{isRegistrationDateInPast(event?.msevtmgt_stopwebsiteregistrationson)? 'Onlineanmeldung nicht mehr möglich':'Anmelden'}}"
          variant="primary"
          class="button-margin"
          size="lg"
          type="button"
          [disabled] = "isParticipantRegistered() || isEventInPast(event?.msevtmgt_eventid!) || isRegistrationDateInPast(event?.msevtmgt_stopwebsiteregistrationson)"
          *ngIf="!isParticipantRegistered() && !isEventInPast(event?.msevtmgt_eventid!)"
          (click)="onStartRegisterEvent(event?.msevtmgt_eventid!)"
          >Jetzt anmelden</sd-2-11-0-button
        >
        <style>
          #part-base sd-2-11-0-button::part(base) {
            border: solid 1px #cc1937;
          }
          #part-base {
            display: inline;
          }
        </style>
        <div id="part-base">
          <sd-2-11-0-button
            class="button-margin"
            title="Stornieren"
            variant="secondary"
            size="lg"
            type="button"
            *ngIf="isParticipantRegistered() && !isEventInPast(event?.msevtmgt_eventid!)"
            (click)="onTryCancelEvent(event?.msevtmgt_eventid!)"
          >
            <div style="color: #cc1937">Teilnahme stornieren</div>
          </sd-2-11-0-button>
        </div>
        <sd-2-11-0-button
          (click)="downloadICSFile()"
          title="Event im Kalender speichern"
          variant="secondary"
          size="lg"
          type="button"
          *ngIf="event && event.crmikpk_kalendereintrageinblenden">
          Im Kalender speichern
        </sd-2-11-0-button>
      </div>

      <div *ngIf="isRoadshow()" class="container-roadshow">
        <iko-va-roadshow-registration [childEvents]="this.getChildren(event)"
          [participantStatus]="this.participantStatusEventId"
          (onRegisterEvent)="onStartRegisterEvent($event)"
          (onCancelEvent)="onTryCancelEvent($event)"
        >
        </iko-va-roadshow-registration>
      </div>

      <img class="image-margin" src="{{getBannerImageURL()}}">

      <iko-va-topics [event]="event!"></iko-va-topics>

      <div class="sd-2-11-0-container sd-2-11-0-container--variant-border-neutral-400" *ngIf="event?.crmikpk_Quotefrom != null && event?.crmikpk_quote != null">
         <iko-va-quote [event]="event!"></iko-va-quote>
      </div>

    </iko-va-grid>

    <iko-va-grid type="item" [span]="['xl-4', '2xl-4']" class="mt-3">
      <iko-va-event-meta-information [event]="event!" />
    </iko-va-grid>

  </iko-va-grid>

  <iko-va-event-selector
    *ngIf="isRoadshow()"
    (eventChange)="this.childEventSelected($event)"
    [childEvents]="this.getChildren(event)"
    [selectedChildEvent]="selectedChildEvent"
  />

  <div class="sd-2-11-0-container" *ngIf="this.speakers && this.speakers.length > 0">
    <iko-va-speakers [speakers]="this.speakers"></iko-va-speakers>
  </div>

  <iko-va-container *ngIf="this.sessions && this.sessions.length > 0">
    <iko-va-agenda [sessions]="this.sessions" [multipleDays]="this.isMultiDayEvent"></iko-va-agenda>
  </iko-va-container>
  <iko-va-container *ngIf="!this.sessions || this.sessions.length === 0">
    <iko-va-error-message [errorName]="'Für diese Veranstaltung wurde noch keine Agenda veröffentlicht'"></iko-va-error-message>
  </iko-va-container>

  <iko-va-cancel-registration-modal
    [cancelRegistration]="this.cancelEventRegistration"
    [cancelSucessful]="this.cancelRegistrationSucessful"
  ></iko-va-cancel-registration-modal>

  <iko-va-signup-form-modal [registrationSucessful]="this.registrationSucessfulDelagate"></iko-va-signup-form-modal>

  <iko-va-registration-success-modal [(event)]="this.event_success_msg">

  </iko-va-registration-success-modal>

</ng-template>
