import { Injectable } from '@angular/core';
import {
  LoginService,
  loginServiceInitialize,
  LoginServiceOptions,
  LoginServiceUserProfile,
} from '@microfrontend-toolkit/login-service';
import { LOGIN_SERVICE_KEY } from '@microfrontend-toolkit/login-service/constants';
import { User } from 'oidc-client-ts';
import { environment } from 'src/environments/environment';

import { ServiceProvider } from '../helpers/ServiceProvider';

type LoginServiceUser = User | void;

// Fallback Config when Shell is not present
const OIDC = {
  authority: environment.auth.authority,
  client_id: environment.auth.client_id,
  scope: environment.auth.scope,
  redirect_uri: environment.auth.redirect_uri,
  post_logout_redirect_uri: environment.auth.post_logout_redirect_uri,
} as const;

type AuthServiceStatus = 'uninitialized' | 'available' | 'unavailable';

@Injectable()
export class AuthService extends ServiceProvider {
  loginService!: LoginService;
  authenticatedUser: string | undefined;

  protected status: AuthServiceStatus = 'uninitialized';

  get authServiceStatus() {
    return this.status;
  }

  constructor() {
    super();

    if (this.authServiceStatus !== 'uninitialized') {
      return;
    }

    const parentLoginService =
      this._serviceProvider?.getService<LoginService>(LOGIN_SERVICE_KEY);

    if (!parentLoginService) {
      if (!environment.test)
        console.warn('No login service found! Initializing loginservice...');
      this.loginService = this.setupLoginService();
    } else {
      this.loginService = parentLoginService;
    }
    this.status = 'available';
  }

  setupLoginService = (): LoginService => {
    const loginOptions: LoginServiceOptions = {
      authority: OIDC.authority,
      redirect_uri: OIDC.redirect_uri,
      client_id: OIDC.client_id,
      post_logout_redirect_uri: OIDC.post_logout_redirect_uri,
      scope: OIDC.scope,
    };

    return loginServiceInitialize(loginOptions);
  };

  /**
   * Retrieves login token from auth service
   * @returns a string id_token or null if the user was not authenticated
   */
  public getAuthToken(): Promise<string | undefined> {
    if (environment.useMockAuthService) {
      return new Promise<string>((resolve) => resolve('i am a token'));
    }
    return this.loginService.getLoginToken();
  }

  /**
   * Retrieves data of current session
   * @returns data of current session
   */
  public getSession(): Promise<LoginServiceUser> {
    if (environment.useMockAuthService) {
      return new Promise((resolve) =>
        resolve(
          new User({
            access_token: '',
            token_type: '',
            profile: {
              aud: '',
              exp: 3600,
              iat: 1,
              iss: '',
              sub: '',
            },
          })
        )
      );
    }
    return this.loginService.getUserData();
  }

  /**
   * Asynchronous fetch of a Map containing all user profile data that
   * was provided as ID Claims by the oidc provider.
   * @returns profile of current user
   */
  public getProfile(): Promise<LoginServiceUserProfile> {
    if (environment.useMockAuthService) {
      return new Promise<LoginServiceUserProfile>((resolve) =>
        resolve(<LoginServiceUserProfile>{
          profile: {},
          email: 'b2bportaleetoe@b2cunioninvestmentdev.onmicrosoft.com',
        })
      );
    }
    return this.loginService.getUserProfileData();
  }

  public getUser(): Promise<LoginServiceUser> {
    if (environment.useMockAuthService) {
      return new Promise<LoginServiceUser>((resolve) =>
        resolve(<LoginServiceUser>(<unknown>{
          profile: {
            roles: ['TestUser'],
          },
        }))
      );
    }
    return this.loginService.getUserData();
  }

  /**
   * Triggers a redirect to external oidc provider if the user was not yet authenticated.
   * If user already has a session store token, nothing will happened.
   * In a login scenario, no return value will be submitted as the browser pages gets redicted
   * and leaves current portal.
   * @returns Promise for opening login page
   */
  public login(): Promise<void> {
    if (environment.useMockAuthService) {
      return new Promise<void>((resolve) => resolve());
    }
    return this.loginService.login();
  }

  /**
   * Silently logging in user
   * @returns Promise for logging user in silently
   */
  public silentLogin(): Promise<LoginServiceUser> {
    if (environment.useMockAuthService) {
      return new Promise<void>((resolve) => resolve());
    }
    return this.loginService.silentLogin();
  }

  /**
   * Remove the user from the local session storage and also triggers a remote logout on oidc authority.
   * @returns Promise
   */
  public logout(): Promise<void> {
    if (environment.useMockAuthService) {
      return new Promise<void>((resolve) => resolve());
    }
    return this.loginService.logout();
  }

  /**
   * Checks for the login state of the current user.
   * @returns true if a logged in users state exists, false otherwise
   */
  public isAuthenticated(): Promise<boolean> {
    if (environment.useMockAuthService) {
      return new Promise<boolean>((resolve) => resolve(true));
    }

    return this.loginService.isAuthenticated();
  }
}
