import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { TagVariants } from '../../types/Enums';

@Component({
  selector: 'iko-va-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})
export class TagComponent implements OnInit {
  @Input() variant: TagVariants = TagVariants.Primary;
  @HostBinding('class') class = this.variant.toString();

  ngOnInit(): void {
    this.class = this.variant.toString();
  }
}
