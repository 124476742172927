import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { SpeakerHelper } from 'src/app/helpers/SpeakerHelper';
import { msevtmgt_speaker } from 'src/app/models/msevtmgt_speaker';

import { ModalHelper } from '../../helpers/ModalHelper';

type Speaker = msevtmgt_speaker | undefined | null;

@Component({
  selector: 'iko-va-speaker-modal',
  templateUrl: './speaker-modal.component.html',
  styleUrls: ['./speaker-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeakerModalComponent extends ModalHelper {
  @HostBinding('class.isOpen') isModalOpen = false;

  private _speaker?: msevtmgt_speaker;

  @Output() speakerChange = new EventEmitter<msevtmgt_speaker | undefined>();

  @Input() set speaker(value: Speaker) {
    this._speaker = value === null ? undefined : value;
    if (value === undefined) {
      this.close();
    } else if (value !== null){
      this.open();
    }
  }

  get speaker(): Speaker {
    return this._speaker;
  }

  constructor() {
    super({ enableCloseOnEscape: true });
    this.closeOnEscapeFunction = () => {
      this.isModalOpen = false;
      this.speakerChange.emit(undefined);
    };
  }

  getSpeakerImage(speaker: Speaker) {
    return SpeakerHelper.getSpeakerImage(speaker);
  }

  getMeta(speaker: Speaker): string {
    return SpeakerHelper.getMetaDetails(speaker);
  }

  open() {
    this.isModalOpen = true;
    this._open();
  }

  close() {
    this.isModalOpen = false;
    this.speakerChange.emit(undefined);
    this._close();
  }
}
