<div class="hover-container">
  <ng-content></ng-content>
  <sd-2-11-0-link
    class="readmore-link"
    href="#"
    (click)="readMore($event)"
    id="lnkReadMre"
  >
    weiterlesen
  </sd-2-11-0-link>
</div>
