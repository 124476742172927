import { environment } from 'src/environments/environment';

import { msevtmgt_speaker } from '../models/msevtmgt_speaker';

const getSpeakerImage = (speaker: msevtmgt_speaker | undefined | null):string => {
  if(speaker && speaker.crmikpk_msevtmgt_speakerimage && speaker.crmikpk_msevtmgt_speakerimage.msdyncrm_blobcdnuri){
    return speaker.crmikpk_msevtmgt_speakerimage?.msdyncrm_blobcdnuri;
  }
  if (speaker && speaker.entityimage) {
    return 'data:image/jpg;base64,' + speaker.entityimage;
  }
  return environment.imagesEndpoint + '/default_contact_image.png';
};

const getMetaDetails = (
  speaker: msevtmgt_speaker | undefined | null
): string => {
  const jobTitle = speaker?.crmikpk_speakerjobtitle
    ? `${speaker.crmikpk_speakerjobtitle}`
    : '';
  const company = speaker?.crmikpk_speakeraccountname
    ? `${speaker.crmikpk_speakeraccountname}`
    : '';
  if (speaker) {
    if (jobTitle && company) {
      return `${jobTitle} | ${company}`;
    } else {
      return jobTitle || company;
    }
  } else {
    return '';
  }
};

export const SpeakerHelper = { getSpeakerImage, getMetaDetails };
