import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, concatMap, forkJoin, from, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { DateUtility } from '../helpers/DateUtility';
import { EventServiceHelper } from '../interfaces/EventService';
import { HttpServiceHelper } from '../interfaces/HttpService';
import { contact } from '../models/contact';
import { crmikpk_participantstatus } from '../models/crmikpk_participantstatus';
import { msevtmgt_event } from '../models/msevtmgt_event';
import { HTTP_HELPER } from '../providers/http.helper.provider';
import { queries } from '../queries/';
import { DynamicsAPIResponse } from '../types/DynamicsAPIResponse';

import { AuthService } from './Auth.service';
import { UserService } from './User.service';

@Injectable()
export class EventService implements EventServiceHelper {
  private authToken: string | undefined;
  private _participantUrl = `${environment.crm.apiEndpoint}/${queries.participantStatusEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}&`;
  private _eventsUrl = `${environment.crm.apiEndpoint}/${queries.eventDetailsEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}&`;

  constructor(
    @Inject(HTTP_HELPER) private http: HttpServiceHelper,
    private userService: UserService,
    private authService: AuthService
  ) {}

  public getPublishedEventsByContactID(
    gone?: boolean
  ): Observable<crmikpk_participantstatus[] | null> {
    const url = this._participantUrl;

    return from(this.authService.getAuthToken()).pipe(
      concatMap((authToken) => {
        this.authToken = authToken;
        return this.userService.getUser(authToken);
      }),
      concatMap((contacts) => {
        if (!contacts) return of(null);

        const queries: Observable<
          DynamicsAPIResponse<crmikpk_participantstatus>
        >[] = [];
        contacts.forEach((participant) => {
          queries.push(this.getContactEvents(url, participant, gone));
        });
        return forkJoin(queries);
      }),
      concatMap((apiResponse) => {
        if (!apiResponse) return of(null);
        const allEvents = apiResponse
          .flatMap((r) => r.value)
          .filter(
            (e) => {
              
              if(e.crmikpk_eventid
                ?.crmikpk_msevtmgt_event_msdyncrm_customerjourney_eventid &&
              e.crmikpk_eventid
                ?.crmikpk_msevtmgt_event_msdyncrm_customerjourney_eventid
                .length > 0){
                  return e.crmikpk_eventid
                    .crmikpk_msevtmgt_event_msdyncrm_customerjourney_eventid.some(cj=>{
                      const date = DateUtility.convertToDate(cj.msdyncrm_startdatetime,true);
                      return date && date <= new Date();
                    });
                }return false;
              }
          )
          .sort(
            (a, b) =>
              (a.crmikpk_currentstatusdetailcode ?? 0) -
              (b.crmikpk_currentstatusdetailcode ?? 0)
          );
        const distinctEvents = allEvents.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                ((o.crmikpk_child_event_id || item.crmikpk_child_event_id) &&
                  o.crmikpk_child_event_id?.msevtmgt_eventid ===
                    item.crmikpk_child_event_id?.msevtmgt_eventid) ||
                o.crmikpk_eventid?.msevtmgt_eventid ===
                  item.crmikpk_eventid?.msevtmgt_eventid
            )
        );

        return of(distinctEvents);
      }),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  private getContactEvents(
    url: string,
    participant: contact,
    gone?: boolean
  ): Observable<DynamicsAPIResponse<crmikpk_participantstatus>> {
    let subquery = `participantid=${participant.contactid}`;
    const today = new Date();
    if (!gone) {
      subquery += `&startdate=${encodeURIComponent(today.toISOString())}`;
    }
    else{ // Get Events 2 years ago
      subquery += `&startdate=${encodeURIComponent((new Date(today.getFullYear()-2, today.getMonth(), today.getDate()).toISOString()))}`;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
      }),
    };

    return this.http.get<DynamicsAPIResponse<crmikpk_participantstatus>>(
      url + subquery,
      httpOptions
    );
  }

  public getParticipantStatus(
    eventId: string
  ): Observable<crmikpk_participantstatus[] | null> {
    return from(this.authService.getAuthToken()).pipe(
      concatMap((authToken) => {
        this.authToken = authToken;
        return this.userService.getUser(authToken);
      }),
      concatMap((contacts) => {
        if (!contacts || 0 === contacts.length) return of(null);
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.authToken,
          }),
        };

        const queries: Observable<
          DynamicsAPIResponse<crmikpk_participantstatus>
        >[] = [];
        contacts.forEach((participant) => {
          const subquery = `participantid=${participant.contactid}&eventid=${eventId}`;
          queries.push(
            this.http.get<DynamicsAPIResponse<crmikpk_participantstatus>>(
              this._participantUrl + subquery,
              httpOptions
            )
          );
        });
        return forkJoin(queries);
      }),
      concatMap((apiResponse) => {
        if (!apiResponse) return of(null);
        return of(apiResponse.flatMap((r) => r.value));
      }),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  public getEventByID(
    guid: string,
    isParent: boolean = false
  ): Observable<msevtmgt_event | null> {
    return from(this.authService.getAuthToken()).pipe(
      concatMap((token) => {
        let subquery = `eventid=${guid}`;
        if (isParent) {
          subquery += '&isparent=1';
        }

        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        };

        return this.http
          .get<DynamicsAPIResponse<msevtmgt_event>>(
            this._eventsUrl + subquery,
            httpOptions
          )
          .pipe(
            concatMap((apiResponse) => {
              if (!apiResponse || 0 === apiResponse.value.length)
                return of(null);

              const event = apiResponse.value[0];
              return of(event);
            }),
            catchError((err) => {
              console.error(err);
              return of(null);
            })
          );
      })
    );
  }
}
