<sd-2-11-0-teaser
  [variant]="this.cardVariant"
  breakpoint="9999"
  class="teaser"
  inset
>
  <div slot="media" class="media">
    <img
      class="image"
      [src]="getImage()"
      [alt]="getFormattedEventName() || 'Event Teaser'"
    />

    <div class="locations">
      <iko-va-tag
        class="location"
        *ngIf="this.locations.length > 0"
        [variant]="cityTagVariant"
      >
        {{ this.locations[0] }}
      </iko-va-tag>
      <iko-va-tag
        class="location"
        *ngIf="this.locations.length > 1"
        [variant]="cityTagVariant"
      >
        +{{ this.locations.length - 1 }} weitere
      </iko-va-tag>

      <!--136470001 equals Webconference-->
      <iko-va-tag
        class="location"
        *ngIf="
          participantStatus?.crmikpk_child_event_id?.msevtmgt_eventtype ===
          136470001
        "
        [variant]="cityTagVariant"
      >
        Livestream
      </iko-va-tag>
    </div>
  </div>
  <div slot="meta" class="meta">
    <time
      class="sd-2-11-0-meta timestamp"
      [ngClass]="this.isFirstElement ? 'sd-2-11-0-meta--inverted' : ''"
      >{{ getFormattedDateSpan() }}</time
    >
    <iko-va-tag
      class="status"
      [ngClass]="isFirstElement ? 'inverted' : getStatusClass()"
      *ngIf="status && isPastEvent"
    >
      {{ status }}
    </iko-va-tag>
  </div>
  <h2
    slot="headline"
    class="sd-2-11-0-headline sd-2-11-0-headline--size-lg event-name"
    [ngClass]="this.isFirstElement ? 'sd-2-11-0-headline--inverted': ''"
  >
    {{ getFormattedEventName() }}
  </h2>

  <div class="main">
    <p class="description">{{ getTrimmedDescription() }}</p>

    <!-- Hide Marker with ngIf Condition - marker feature planned after first mvp release -->
    <div class="footer">
      <iko-va-tag
        class="status"
        [ngClass]="isFirstElement ? 'inverted' : getStatusClass()"
        *ngIf="status && !isPastEvent"
      >
        {{ status }}
      </iko-va-tag>
    </div>
  </div>
</sd-2-11-0-teaser>
