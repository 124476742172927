import { Injectable } from '@angular/core';
import {
  trackingServiceInitialize,
  type TrackService,
  type TrackServiceOptions,
  WebtrekkEventData,
} from '@microfrontend-toolkit/tracking-service';
import { TRACK_SERVICE_KEY } from '@microfrontend-toolkit/tracking-service/constants';
import { environment } from 'src/environments/environment';

import { ServiceProvider } from '../helpers/ServiceProvider';

import { AuthService } from './Auth.service';

const trackServiceOptions: TrackServiceOptions = {
  config: {
    domain: environment.tracking.domain,
    trackID: environment.tracking.trackID,
    trackDomain: environment.tracking.trackDomain,
    contentID: environment.tracking.contentID,
    supressInitialPageRequest: true,
    useCookies: false,
  },
  hasConsent: true,
};

type SessionUser = {
  roles: string;
  authStatus: 'logged in' | 'logged out';
};

type TrackingServiceStatus = 'uninitialized' | 'available' | 'unavailable';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class TrackingService extends ServiceProvider {
  private trackService?: TrackService;
  private enabled: boolean = environment.tracking.enabled || false;
  private trackingServiceStatus: TrackingServiceStatus = 'uninitialized';
  private sessionUser: SessionUser | null = null;

  constructor(private authService: AuthService) {
    super();
    if (!this.enabled) {
      if (!environment.test) console.warn('TrackingService is disabled!');
      return;
    }
    this.trackService =
      this._serviceProvider.getService<TrackService>(TRACK_SERVICE_KEY);
  }

  public async init() {
    try {
      if (!this.enabled) return;

      if (!this.trackService) {
        await this.createCustomTrackingService();
      } else {
        this.trackingServiceStatus = 'available';
      }
      await this.initUser();
    } catch (ex) {
      console.error(ex);
    }
  }

  public async initUser() {
    if (!this.sessionUser) {
      const sessionUser = await this.getSessionUser();
      this.sessionUser = sessionUser;
    }
  }

  private async createCustomTrackingService() {
    this.trackService = await trackingServiceInitialize(trackServiceOptions);
    if (!this.trackService) {
      if (!environment.test)
        console.warn(
          'Tracking service could not be initialized. This service will not work!'
        );
      this.trackingServiceStatus = 'unavailable';
    }
    this.trackingServiceStatus = 'available';
  }

  private async getSessionUser(): Promise<SessionUser | null> {
    const userData = await this.authService?.getUser();
    const userRoles = userData?.profile['roles'] as string[];
    const isLoggedIn = await this.authService?.isAuthenticated();

    return {
      roles: userRoles ? userRoles.join(', ') : '',
      authStatus: isLoggedIn ? 'logged in' : 'logged out',
    };
  }

  /**
   * Tracks webtrekk event
   * @param data webtrekk event data
   */
  track(data: WebtrekkEventData) {
    if (
      !this.enabled ||
      this.trackingServiceStatus === 'unavailable' ||
      this.trackingServiceStatus === 'uninitialized'
    ) {
      if (!environment.test)
        console.warn(
          'Tracking service is disabled, not initialized or unavailable'
        );
      return;
    }
    const trackingData = structuredClone(data);

    // Set custom session parameter for page and virtual page events
    if (
      (data.eventInfo.eventType === 'Page' ||
        data.eventInfo.eventType === 'VirtualPage') &&
      this.sessionUser !== null
    ) {
      trackingData['attributes'] = {
        ...trackingData['attributes'],
        customSessionParameter: {
          2: this.sessionUser.authStatus,
          7: this.sessionUser.roles,
        },
      };

      // Must be set or errors gets thrown by tracking service
      trackingData['category'] = {
        contentGroups: [],
      };
    }
    this.trackService?.track(trackingData);
  }
}
