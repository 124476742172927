interface ModalSettings {
  enableCloseOnEscape?: boolean;
}

export class ModalHelper {
  protected closeOnEscape: boolean = true;
  protected closeOnEscapeFunction?: () => void;

  constructor({ enableCloseOnEscape }: ModalSettings) {
    this.closeOnEscape =
      enableCloseOnEscape !== undefined ? enableCloseOnEscape : true;
  }

  protected _open() {
    this.hideOverFlow();
    window.addEventListener('keydown', this._keydownHandler);
  }

  protected _close() {
    this.resetOverflow();
    window.removeEventListener('keydown', this._keydownHandler);
  }

  private _keydownHandler = (e: Event) => {
    if ((e as KeyboardEvent).code === 'Escape') {
      this._close();
      this.closeOnEscapeFunction && this.closeOnEscapeFunction();
    }
  };

  protected resetOverflow() {
    document.body.style.overflowY = 'initial';
  }

  protected hideOverFlow() {
    document.body.style.overflowY = 'hidden';
  }
}
