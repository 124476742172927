import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


export enum SanitizerTrust {
    Resticted = 1,
    Bypass = 2,
    OnlyColorStyle = 3
  }

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: any, mode: SanitizerTrust = SanitizerTrust.Resticted): SafeHtml |string {//eslint-disable-line @typescript-eslint/no-explicit-any


    switch (mode) {
      case SanitizerTrust.Resticted: return this.sanitizer.sanitize(SecurityContext.HTML, value)!;
      case SanitizerTrust.Bypass: return this.sanitizer.bypassSecurityTrustHtml(value);
      case SanitizerTrust.OnlyColorStyle:
        value = this.removeFontSettings(value);
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

  private removeFontSettings(value: string): string {
    const el = document.createElement('powerapp-html');
    el.innerHTML = value;

    const outerElement = el.querySelector('div[data-wrapper]');
    if (outerElement != null)
    {
      const styleAttr = outerElement.getAttribute('style');
      if (styleAttr != null)
      {
        const styles = styleAttr.split(';')
        const stylesNoFontTag = styles.filter(st => {
          const cssProp = st.split(':');
          if (cssProp.length < 2)
          {
            return false;
          }
          switch(cssProp[0].toLowerCase())
          {
            case 'font-family':
            case 'font-size':
              return false;

            default:
              return true;
          }});

          outerElement.setAttribute('style', stylesNoFontTag.join())
      }
    }
    return el.innerHTML;
  }
}
