<div class="backdrop" (click)="this.close()"></div>

<div class="modal">
  <sd-4-0-0-button
    title="Schließen"
    variant="tertiary"
    type="button"
    class="close-btn"
    (click)="this.close()"
    [disabled]="this.currentModalState === 'loading'"
  >
    <sd-4-0-0-icon
      name="system/close"
      library="default"
      color="primary"
      class="close-icon"
    ></sd-4-0-0-icon>
  </sd-4-0-0-button>
  <div class="header" *ngIf="this.currentModalState === 'success'">
    <sd-4-0-0-icon
      class="header-icon"
      name="content/circle-hook"
      library="default"
      color="primary"
    ></sd-4-0-0-icon>

    <h3 class="sd-1-0-1-headline sd-1-0-1-headline--size-3xl heading">
      Teilnahme storniert
    </h3>
  </div>
  <div class="header" *ngIf="this.currentModalState !== 'success'">
    <sd-4-0-0-icon
      class="icon-rotate-45deg header-icon"
      name="content/circle-plus"
      library="default"
      color="primary"
    ></sd-4-0-0-icon>

    <h3 class="sd-1-0-1-headline sd-1-0-1-headline--size-3xl heading">
      Teilnahme endgültig stornieren?
    </h3>
  </div>
  <p
    class="sd-1-0-1-paragraph description"
    *ngIf="this.currentModalState === 'success'"
  >
    Schade, dass Sie nicht teilnehmen. Wir freuen uns, Sie bald bei einer
    unserer kommenden Veranstaltungen willkommen zu heißen.
  </p>
  <p
    class="sd-1-0-1-paragraph description"
    *ngIf="this.currentModalState !== 'success'"
  >
    Sind Sie sicher, dass Sie Ihre Teilnahme an der Veranstaltung
    <strong> {{ this.selectedEvent?.msevtmgt_name }} </strong> stornieren
    möchten?
  </p>
  <iko-va-sd-notification *ngIf="this.currentModalState === 'error'">
    Etwas ist schief glaufen. Bitte versuchen Sie es erneut.
  </iko-va-sd-notification>
  <div class="footer">
    <sd-4-0-0-button
      title="Endgültig stornieren"
      variant="secondary"
      size="lg"
      type="button"
      class="variant-error"
      (click)="this.acceptCancellation()"
      [loading]="this.currentModalState === 'loading'"
      *ngIf="this.currentModalState !== 'success'"
    >
      Endgültig stornieren
    </sd-4-0-0-button>
    <sd-4-0-0-button
      title="Abbrechen"
      variant="secondary"
      size="lg"
      type="button"
      (click)="this.declineCancellation()"
      [disabled]="this.currentModalState === 'loading'"
      *ngIf="this.currentModalState !== 'success'"
    >
    Abbrechen
    </sd-4-0-0-button>
    <sd-4-0-0-button
      title="Zu den Veranstaltungen"
      variant="cta"
      size="lg"
      type="button"
      (click)="this.navigateToEventOverview()"
      *ngIf="this.currentModalState === 'success'"
    >
      Zu den Veranstaltungen
    </sd-4-0-0-button>
  </div>
</div>
