import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import '@solid-design-system/components/dist-versioned';

import { AgendaComponent } from './components/agenda/agenda.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ChipFilterComponent } from './components/chip-filter/chip-filter.component';
import { ContainerComponent } from './components/container/container.component';
import { EventCardComponent } from './components/event-card/event-card.component';
/**
 * Components
 */
import { EventListComponent } from './components/event-list/event-list.component';
import { EventselectorComponent } from './components/event-selector/event-selector.component';
import { FullScreenLoaderComponent } from './components/full-screen-loader/full-screen-loader.component';
import { GridComponent } from './components/grid/grid.component';
import { QuoteComponent } from './components/quote/quote.component';
import { ReadMoreLinkComponent } from './components/read-more-link/read-more-link.component';
import { RoadshowRegistrationComponent } from './components/roadshow-registration/roadshow-registration.component';
import { RoadshowRegistrationAddrComponent } from './components/roadshow-registration-addr/roadshow-registration-addr.component';
import { SocialMediaLinksComponent } from './components/social-media-links/social-media-links.component';
import { SpeakerModalComponent } from './components/speaker-modal/speaker-modal.component';
import { SpeakersComponent } from './components/speakers/speakers.component';
import { TagComponent } from './components/tag/tag.component';
import { TopicsComponent } from './components/topics/topics.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
/**
 * Services
 */
import { SanitizeHtmlPipe } from './helpers/SanitizeHtmlPipe';
/**
 * Providers
 */
import { HttpHelperProvider } from './providers/http.helper.provider';
import { AuthService } from './services/Auth.service';
import { EventService } from './services/Event.service';
import { RegistrationService } from './services/Registration.service';
import { RoutingService } from './services/Routing.service';
import { UserService } from './services/User.service';
import { EventDetailsComponent } from './views/event-details/event-details.component';
/**
 * Views
 */
import { EventOverviewComponent } from './views/event-overview/event-overview.component';
import { TAG_NAME, TAG_NAME_WIDGET } from './config';

registerLocaleData(localeDe);
import { CancelRegistrationModalComponent } from './components/cancel-registration-modal/cancel-registration-modal.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { EventMetaInformationComponent } from './components/event-meta-information/event-meta-information.component';
import { RegistrationCustomFieldComponent } from './components/registration-custom-field/registration-custom-field.component';
import { RegistrationSuccessModalComponent } from './components/registration-success-modal/registration-success-modal.component';
import { SdNotificationComponent } from './components/sd-notification/sd-notification.component';
import { SignupFormModalComponent } from './components/signup-form-modal/signup-form-modal.component';
import { AppInsightsService } from './helpers/AppInsights';
import { CacheService } from './services/Cache.service';
import { TrackingService } from './services/Tracking.service';
import { EventWidgetCardComponent } from './widgets/events-widget/event-widget-card/event-widget-card.component';
import { EventsWidgetComponent } from './widgets/events-widget/events-widget.component';

@NgModule({
  declarations: [
    ChipFilterComponent,
    EventCardComponent,
    EventDetailsComponent,
    EventListComponent,
    EventOverviewComponent,
    TagComponent,
    FullScreenLoaderComponent,
    WrapperComponent,
    GridComponent,
    ContainerComponent,
    QuoteComponent,
    TopicsComponent,
    SanitizeHtmlPipe,
    BreadcrumbComponent,
    AgendaComponent,
    EventselectorComponent,
    SpeakersComponent,
    EventMetaInformationComponent,
    ReadMoreLinkComponent,
    SpeakerModalComponent,
    SocialMediaLinksComponent,
    RoadshowRegistrationComponent,
    RoadshowRegistrationAddrComponent,
    CancelRegistrationModalComponent,
    SdNotificationComponent,
    SignupFormModalComponent,
    RegistrationSuccessModalComponent,
    RegistrationCustomFieldComponent,
    EventsWidgetComponent,
    EventWidgetCardComponent,
    ErrorMessageComponent,
  ],
  imports: [CommonModule, BrowserModule, ReactiveFormsModule, FormsModule],
  providers: [
    AuthService,
    EventService,
    RegistrationService,
    UserService,
    HttpHelperProvider,
    RoutingService,
    CacheService,
    TrackingService,
    provideHttpClient(),
    { provide: LOCALE_ID, useValue: 'de' },
    AppInsightsService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  
    constructor(injector: Injector) {
    
    const webComponent = createCustomElement(WrapperComponent, { injector });
    customElements.define(TAG_NAME, webComponent);
    if (environment.name === 'local' || environment.name === 'development')
      // eslint-disable-next-line no-console
      console.debug(
        `Webcomponent registered in ${environment.name} environment`
      );

    const widgetComponent = createCustomElement(EventsWidgetComponent, {
      injector,
    });
    customElements.define(TAG_NAME_WIDGET, widgetComponent);
    if (environment.name === 'local' || environment.name === 'development')
      // eslint-disable-next-line no-console
      console.debug(
        `Widget Webcomponent registered in ${environment.name} environment`
      );
  }
  ngDoBootstrap() {}
}
