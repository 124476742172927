import { Route } from './types/Route';

export const TAG_NAME = 'va-events';
export const TAG_NAME_WIDGET = 'va-widget';

export enum ROUTE_IDS {
  HOME = 'home',
  DETAILS = 'details',
}

export const ROUTES = {
  [ROUTE_IDS.HOME]: {
    id: ROUTE_IDS.HOME,
    title: 'Übersicht',
  } as Route,
  [ROUTE_IDS.DETAILS]: {
    id: ROUTE_IDS.DETAILS,
    title: 'Details',
  } as Route,
};
