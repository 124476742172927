import { Component, Input } from '@angular/core';
import { DateUtility } from 'src/app/helpers/DateUtility';
import { getEventName, isInPast } from 'src/app/helpers/EventHelpers';
import { msevtmgt_building } from 'src/app/models/msevtmgt_building';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';

@Component({
  selector: 'iko-va-roadshow-registration-addr',
  templateUrl: './roadshow-registration-addr.component.html',
  styleUrls: ['./roadshow-registration-addr.component.css'],
})
export class RoadshowRegistrationAddrComponent {
  @Input() child!: msevtmgt_event;

  isInPast() {
    return isInPast(this.child);
  }

  getEventTime(): string {
    try {
      const dayString = new Date(
        this.child.msevtmgt_eventstartdate!
      ).toLocaleString('de-de', { weekday: 'long' });
      const timeString = DateUtility.formatRangeTime(
        new Date(this.child.msevtmgt_eventstartdate!),
        new Date(this.child.msevtmgt_eventenddate!),
        true,
        ' - ',
        false,
        false
      );
      return `${dayString} | ${timeString}`;
    } catch (e) {
      console.error(e);
      return '';
    }
  }
  getTitle(): string {
    return getEventName(this.child);
  }

  getLocation(building: msevtmgt_building | undefined): string {
    let address = '';
    if (building?.msevtmgt_addressline1) {
      address += building.msevtmgt_addressline1 + ' ';
    }
    if (building?.msevtmgt_addressline2) {
      if (building?.msevtmgt_addressline1) {
        address += '| ';
      }
      address += building.msevtmgt_addressline2 + ' ';
    }
    if (building?.msevtmgt_addressline3) {
      if (building?.msevtmgt_addressline1 || building?.msevtmgt_addressline2) {
        address += '| ';
      }
      address += building.msevtmgt_addressline3 + ' ';
    }
    if (building?.msevtmgt_city) {
      if (
        building?.msevtmgt_addressline1 ||
        building?.msevtmgt_addressline2 ||
        building?.msevtmgt_addressline3
      ) {
        address += '| ';
      }
      if (building?.msevtmgt_postalcode) {
        address += building?.msevtmgt_postalcode + ' ';
      }
      address += building?.msevtmgt_city;
    }
    if ('' == address) {
      return 'Kein Standort';
    }
    return address;
  }
}
