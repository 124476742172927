import {
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ROUTE_IDS } from 'src/app/config';
import { ModalHelper } from 'src/app/helpers/ModalHelper';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { RegistrationService } from 'src/app/services/Registration.service';
import { RoutingService } from 'src/app/services/Routing.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { environment } from 'src/environments/environment';

type CancelEventRegistration = (event: msevtmgt_event, contact: string,service:RegistrationService) => Promise<boolean>;
type OnCancellationSuccessful = () => void;
type ModalState = 'idle' | 'loading' | 'error' | 'success';

@Component({
  selector: 'iko-va-cancel-registration-modal',
  templateUrl: './cancel-registration-modal.component.html',
  styleUrls: ['./cancel-registration-modal.component.css'],
})
export class CancelRegistrationModalComponent extends ModalHelper {
  @HostBinding('class.isOpen') isModalOpen = false;

  @Input({ required: true })
  cancelRegistration!: CancelEventRegistration;

  @Input()
  cancelSucessful?: OnCancellationSuccessful;

  currentModalState: ModalState = 'idle';
  selectedEvent?: msevtmgt_event;
  selectedContactid?: string;

  constructor(private routingService: RoutingService,
    private trackingService: TrackingService,
    private registrationService:RegistrationService) {
    super({ enableCloseOnEscape: true });
    this.closeOnEscapeFunction = () => {
      // Prevent user from closing modal with esc key during loading state
      if (this.currentModalState === 'loading') return;
      this.resetModalState();
      this.isModalOpen = false;
    };
  }

  async acceptCancellation() {
    this.currentModalState = 'loading';
    this.trackingService.track({
      eventInfo: {
        eventType: 'Action',
        eventName: `Click 'Endgültig stornieren'`,
      },
      category: {
        area: 'Content',
      },
      attributes: {
        elementType: 'Button',
        elementLabel: 'Endgültig stornieren',
        target: 'cancel-registration-modal-success',
        customECommerceParameter: {
          48: 'sd-2-11-0-button',
          49: 'sd-2-11-0',
          50: environment.app.NAME,
        },
      },
    });
    const isSuccessful = await this.cancelRegistration(this.selectedEvent!, this.selectedContactid!, this.registrationService);
    this.currentModalState = isSuccessful ? 'success' : 'error';
    this.cancelSucessful && this.cancelSucessful();
  }

  declineCancellation() {
    this.close();
  }

  navigateToEventOverview() {
    window.history.pushState({}, '', '/veranstaltungen');
    this.routingService.dispatchRouteChangeEvent({
      page: ROUTE_IDS.HOME,
    });
  }

  open(event: msevtmgt_event, contactid:string) {
    this.selectedEvent = event;
    this.selectedContactid = contactid;
    this.isModalOpen = true;
    this._open();
  }

  /**
   * Reset modal state if user closes modal after already tried canceling event registration
   */
  resetModalState() {
    if (
      this.currentModalState === 'success' ||
      this.currentModalState === 'error'
    ) {
      this.currentModalState = 'idle';
    }
  }

  close() {
    // Prevent user from closing modal with clicking in backdrop during loading state
    if (this.currentModalState === 'loading') return;

    this.resetModalState();

    this.isModalOpen = false;
    this._close();
  }
}
