<div class="sd-2-11-0-paragraph sd-2-11-0-paragraph--size-sm" *ngIf="child != null">
  {{getEventTime()}}
  <br />
  <strong>{{ getTitle() }}{{child.msevtmgt_building?.msevtmgt_name? " | "+ child.msevtmgt_building?.msevtmgt_name:""}}</strong>
  <br />
  {{ getLocation(child.msevtmgt_building) }}
  <div *ngIf="isInPast()">
    <sd-2-11-0-notification open="" variant="info" toast-stack="top-right" duration="Infinity">
      <div>Diese Veranstaltung liegt in der Vergangenheit</div>
    </sd-2-11-0-notification>
  </div>
</div>