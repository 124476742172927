import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, concatMap, from, Observable, of, } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpServiceHelper } from '../interfaces/HttpService';
import { RegistrationServiceHelper } from '../interfaces/RegistrationService';
import { msevtmgt_eventcustomregistrationfield } from '../models/msevtmgt_customregistrationfield';
import { msevtmgt_registrationresponse } from '../models/msevtmgt_registrationresponse';
import { HTTP_HELPER } from '../providers/http.helper.provider';
import { queries } from '../queries';
import { DynamicsAPIResponse } from '../types/DynamicsAPIResponse';

import { AuthService } from './Auth.service';

@Injectable()
export class RegistrationService implements RegistrationServiceHelper {
  private _registrationUrl = `${environment.crm.apiEndpoint}/${queries.eventregistrationEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}`;
  private _registrationresponseUrl = `${environment.crm.apiEndpoint}/${queries.registrationresponseEndpoint}/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}`;
  private _cancelationUrl = `${environment.web_marketing.registrationEndpoint}`;

  constructor(
    @Inject(HTTP_HELPER) private http: HttpServiceHelper,
    private authService: AuthService
  ) {}

  public registerEvent(
    eventid: string,
    contactid: string
  ): Observable<any | null> {//eslint-disable-line @typescript-eslint/no-explicit-any
    return from(this.authService.getAuthToken()).pipe(
      concatMap((token) => {
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        };

        const registration = {
          'msevtmgt_EventId@odata.bind': `/msevtmgt_events(${eventid})`,
          'msevtmgt_ContactId@odata.bind': `/contacts(${contactid})`,
        };

        return this.http
          .post<DynamicsAPIResponse<any>>(//eslint-disable-line @typescript-eslint/no-explicit-any
            this._registrationUrl,
            JSON.stringify(registration),
            httpOptions
          )
          .pipe(
            concatMap((apiResponse) => {
              return of(apiResponse);
            }),
            catchError((err) => {
              console.error(err);
              return of(null);
            })
          );
      })
    );
  }

  public cancelRegistration(
    eventid: string,
    contactid: string
  ): Observable<any | null> {//eslint-disable-line @typescript-eslint/no-explicit-any
    const cancellationUrl: string = `${this._cancelationUrl}/${eventid}/${contactid}/confirmation?api-version=${environment.web_marketing.registrationApiVersion}`;
    const httpOptions = {headers:new HttpHeaders()}
    httpOptions.headers = httpOptions.headers.append('Content-Type', 'text/html;charset=UTF-8');
    httpOptions.headers = httpOptions.headers.append('Accept', 'text/html;charset=UTF-8');
    return this.http.get<DynamicsAPIResponse<any>>(cancellationUrl,httpOptions).pipe(//eslint-disable-line @typescript-eslint/no-explicit-any
      concatMap((apiResponse) => {
        if (!apiResponse || 0 === apiResponse.value.length) return of(null);
        return of(apiResponse.value[0]);
      }),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  public getCustomRegistrationFields(
    eventid: string
  ): Observable<msevtmgt_eventcustomregistrationfield[] | null> {
    const url = `${environment.crm.apiEndpoint}/customfields/${environment.crm.apiEndpointVersion}?api-version=${environment.crm.apiVersion}&eventid=${eventid}`;
    return from(this.authService.getAuthToken()).pipe(
      concatMap((token) => {
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        };
        return this.http
          .get<DynamicsAPIResponse<msevtmgt_eventcustomregistrationfield>>(url,httpOptions)
          .pipe(
            concatMap((apiResponse) => {
              if (!apiResponse || 0 === apiResponse.value.length)
                return of(null);
              return of(apiResponse.value);
            }),
            catchError((err) => {
              console.error(err);
              return of(null);
            })
          );
      })
    );
  }

  public setCustomRegistrationFields(
    eventregistrationid: string,
    customregistrationfieldid: string,
    response:string
  ): Observable<msevtmgt_registrationresponse | null> {
    return from(this.authService.getAuthToken()).pipe(
      concatMap((token) => {
        const httpOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        };

        const registration = {
          'msevtmgt_eventregistration@odata.bind': `/msevtmgt_eventregistrations(${eventregistrationid})`,
          'msevtmgt_customregistrationfield@odata.bind': `/msevtmgt_customregistrationfields(${customregistrationfieldid})`,
          'msevtmgt_response':response
        };

        return this.http
          .post<DynamicsAPIResponse<msevtmgt_registrationresponse>>(
            this._registrationresponseUrl,
            JSON.stringify(registration),
            httpOptions
          )
          .pipe(
            concatMap((apiResponse) => {
              if (!apiResponse || 0 === apiResponse.value.length)
                return of(null);
              return of(apiResponse.value[0]);
            }),
            catchError((err) => {
              console.error(err);
              return of(null);
            })
          );
      })
    );
  }
}
