<div [ngSwitch]="getFldType()">
  <div *ngSwitchCase="100000000">
    <sd-4-0-0-textarea
      #elem_regfldSimple
      [value]=""
      type="text"
      size="lg"
      [label]="getTitle()"
      [required]="regField?.custom_registration_field?.msevtmgt_customregistrationfield?.msevtmgt_isrequired"
      >
    </sd-4-0-0-textarea>
  </div>

  <div *ngSwitchCase="100000001" class="sd-1-0-1-paragraph title">{{getTitle()}}</div>
  <sd-4-0-0-switch *ngSwitchCase="100000001"
    #elem_regfldBoolean>
  </sd-4-0-0-switch>

  <div *ngSwitchCase="100000003" class="sd-1-0-1-paragraph title">{{getTitle()}}</div>
  <sd-4-0-0-radio-group
    #elem_regfldSingleChoice
    *ngSwitchCase="100000003"
    boldlabel name="radio-group"
    size="lg"
    orientation="vertical">
      <sd-4-0-0-radio *ngFor="let item of getChoices(); index as idx" value="{{idx}}">{{item}}</sd-4-0-0-radio>
  </sd-4-0-0-radio-group>

  <div *ngSwitchCase="100000002" class="sd-1-0-1-paragraph title">{{getTitle()}}</div>
  <sd-4-0-0-checkbox-group
    *ngSwitchCase="100000002"
    size="lg"
    orientation="vertical">
      <sd-4-0-0-checkbox #elem_regfldMultiChoice *ngFor="let item of getChoices(); index as idx" name="checkbox" value="{{idx}}">{{item}}</sd-4-0-0-checkbox>
  </sd-4-0-0-checkbox-group>

</div>
