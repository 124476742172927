import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ROUTE_IDS } from 'src/app/config';
import { DateUtility } from 'src/app/helpers/DateUtility';
import {isParentEventStatus } from 'src/app/helpers/EventHelpers';
import {
  buildEventDetailURL,
  getFormattedDateSpan,
  getFormattedEventName,
  getImage,
  getLocations,
  getStatus,
  getStatusClass,
  getTrimmedDescription,
  isRoadshow,
} from 'src/app/helpers/EventHelpers';
import { crmikpk_participantstatus } from 'src/app/models/crmikpk_participantstatus';
import { msevtmgt_event } from 'src/app/models/msevtmgt_event';
import { RoutingService } from 'src/app/services/Routing.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { TagVariants } from 'src/app/types/Enums';
import RouteChangeEvent from 'src/app/types/RouteChangeEvent';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'iko-va-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardComponent implements OnInit {
  @Input({ required: true }) participantStatus?: crmikpk_participantstatus;
  @Input() @HostBinding('class.isFirstElement') isFirstElement = false;
  @Input() cardVariant?: string;

  status: string | undefined;
  locations: string[] = [];
  isPastEvent: boolean = false;
  isRoadshow: boolean = false;
  cityTagVariant: TagVariants = TagVariants.LightBlue;

  constructor(
    private routingService: RoutingService,
    private trackingService: TrackingService
  ) {}

  @HostBinding() tabindex = 0;
  @HostListener('click', ['$event']) onClick(e: PointerEvent) {
    e.stopPropagation();

    if (!this.participantStatus) return;

    const event =
      this.participantStatus.crmikpk_child_event_id ||
      this.participantStatus.crmikpk_eventid;
    if (event) {
      const isParent = isParentEventStatus(this.participantStatus, event?.msevtmgt_eventid);
      const eventId = event.msevtmgt_eventid;
      let childEventId: string | undefined;
      if (
        this.participantStatus.crmikpk_eventid &&
        !this.participantStatus.crmikpk_eventid.crmikpk_isroadshowbool
      ) {
        childEventId =
          this.participantStatus.crmikpk_child_event_id?.msevtmgt_eventid;
        if (isParent) {
          childEventId = this.getEventId(
            this.participantStatus.crmikpk_eventid
          );
        }
      }

      const url = buildEventDetailURL(
        eventId,
        window.location.origin,
        isParent,
        childEventId
      );

      const state: RouteChangeEvent = {
        page: ROUTE_IDS.DETAILS,
        id: eventId,
        isParent,
      };

      this.trackingService.track({
        eventInfo: {
          eventType: 'Action',
          eventName: `Clicked teaser '${this.getFormattedEventName()}'`,
        },
        category: {
          area: 'Content',
        },
        attributes: {
          elementLabel: this.getFormattedEventName(),
          elementType: 'Teaser',
          customECommerceParameter: {
            48: 'sd-2-11-0-teaser',
            49: 'sd-2-11-0',
            50: environment.app.NAME,
          },
          target: url.toString(),
        },
      });

      window.history.pushState(state, '', url);

      this.routingService.dispatchRouteChangeEvent(state);
    }
  }

  async ngOnInit() {
    if (this.participantStatus && this.participantStatus.crmikpk_eventid) {
      this.isRoadshow = isRoadshow(this.participantStatus.crmikpk_eventid);
    }

    this.status = this.participantStatus && getStatus(this.participantStatus);
    this.locations = this.participantStatus
      ? getLocations(this.participantStatus, this.isRoadshow)
      : [];
    this.cardVariant = this.getCardVariant();
    
    const endDate = DateUtility.getRegistrationEventsDate(this.participantStatus);
    this.isPastEvent =
      endDate !== null && endDate.getTime() < new Date().getTime();

    await this.trackingService.init();
  }

  getFormattedEventName() {
    if (!this.participantStatus) return '';
    return getFormattedEventName(this.participantStatus);
  }

  getFormattedDateSpan() {
    if (!this.participantStatus) return '';
    return getFormattedDateSpan(this.participantStatus);
  }

  getImage() {
    if (!this.participantStatus) return '';
    return getImage(this.participantStatus);
  }

  getStatusClass() {
    if (!this.participantStatus) return '';
    return getStatusClass(this.participantStatus);
  }

  getTrimmedDescription() {
    if (!this.participantStatus) return '';
    return getTrimmedDescription(this.participantStatus);
  }

  getEventId(event: msevtmgt_event): string {
    if (
      1 === event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid?.length
    ) {
      return event.crmikpk_msevtmgt_event_msevtmgt_event_parenteventid[0]
        .msevtmgt_eventid;
    }
    return event.msevtmgt_eventid;
  }

  getCardVariant() {
    if (this.cardVariant !== undefined) return this.cardVariant;
    return this.isFirstElement ? 'primary' : 'white border-neutral-400';
  }
}
