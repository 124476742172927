import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { crmikpk_participantstatus } from '../models/crmikpk_participantstatus';

import { AuthService } from './Auth.service';

export interface CachedPayload {
  savedAt: Date;
  data: any;//eslint-disable-line @typescript-eslint/no-explicit-any
  userId: string;
}

const CACHE_TTL = environment.eventsCache.ttl;

/**
 * Key for storing events in localstorage
 */
export const LOCAL_STORAGE_EVENTS_KEY = 'events' as const;
export enum EVENT_TIMES {
  PAST = 'past',
  FUTURE = 'future',
}

/**
 * Service for using browser cache (currently supported: localStorage)
 */
@Injectable()
export class CacheService {
  private initialized: boolean = false;
  currentUserId: string = '';
  constructor(private authService: AuthService) {}
  
  async init() {
    if(!environment.eventsCache.enabled)
      return
    const profile = await this.authService.getProfile();
    if (typeof profile?.aud === 'string') {
      this.currentUserId = profile?.aud;
    } else {
      this.currentUserId = (profile?.aud && profile?.aud[0]) || '';
    }
    this.initialized = true;
  }

  getEvents(isPastEvents: boolean = false): crmikpk_participantstatus[] | null {
    if(!environment.eventsCache.enabled)
      return null;
    if (!this.initialized) {
      console.warn(
        'Cache will return nothing because service is not intialized. Call service.init() first'
      );
    }
    const localStorageKey = `${
      isPastEvents ? EVENT_TIMES.PAST : EVENT_TIMES.FUTURE
    }-${LOCAL_STORAGE_EVENTS_KEY}`;

    const payload = this.parseData(
      window.localStorage.getItem(localStorageKey)
    );

    if (payload === null || payload.data === null) return null;

    // Check if data is outdated
    const today = new Date();
    if (today.getTime() - new Date(payload.savedAt).getTime() > CACHE_TTL)
      return null;

    // If current user is not the user who retrieved the data
    if (this.currentUserId !== payload.userId) return null;

    return payload.data as crmikpk_participantstatus[];
  }

  saveEvents(
    data: crmikpk_participantstatus[],
    isPastEvents: boolean = false
  ): void {
    if(!environment.eventsCache.enabled)
      return
    if (!this.initialized) {
      console.warn(
        'Cache will return nothing because service is not intialized. Call service.init() first'
      );
    }
    const localStorageKey = `${
      isPastEvents ? EVENT_TIMES.PAST : EVENT_TIMES.FUTURE
    }-${LOCAL_STORAGE_EVENTS_KEY}`;

    const payload: CachedPayload = {
      savedAt: new Date(),
      data: data,
      userId: this.currentUserId,
    };

    window.localStorage.setItem(localStorageKey, JSON.stringify(payload));
  }

  static removeEvents(isPastEvents: boolean = false) {
    const localStorageKey = `${
      isPastEvents ? EVENT_TIMES.PAST : EVENT_TIMES.FUTURE
    }-${LOCAL_STORAGE_EVENTS_KEY}`;

    window.localStorage.removeItem(localStorageKey);
  }

  private parseData(data: string | null) {
    if (data === null) return null;
    try {
      return JSON.parse(data) as CachedPayload;
    } catch (error) {
      console.error("Couldn't parse data", !environment.test ? error : '');
      return null;
    }
  }
}
