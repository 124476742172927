import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { GridType, SpanQuery } from 'src/app/types/Grid';

@Component({
  selector: 'iko-va-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class GridComponent implements OnInit {
  @Input() type: GridType = 'item';
  @Input() span: SpanQuery[] = [];

  @HostBinding('class.grid-container') isContainer: boolean =
    this.type === 'container';
  @HostBinding('class.grid-item') isItem: boolean = this.type === 'item';

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.isContainer = this.type === 'container';
    this.isItem = this.type === 'item';

    if (this.isItem && this.span?.length > 0) {
      this.span.forEach((span) => {
        const [size, spanAmount] = span.split('-');
        this.elementRef.nativeElement.style.setProperty(
          `--var-col-end-${size}`,
          spanAmount
        );
      });
    }
  }
}
