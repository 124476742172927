import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ROUTE_IDS, ROUTES } from 'src/app/config';
import { AuthService } from 'src/app/services/Auth.service';
import { CacheService } from 'src/app/services/Cache.service';
import { RoutingService } from 'src/app/services/Routing.service';
import { AuthState } from 'src/app/types/AuthState';
import { Route } from 'src/app/types/Route';

const getCurrentRoute = (
  defaultRoute: Route = ROUTES[ROUTE_IDS.HOME]
): Route | null => {
  const params = new URLSearchParams(window.location.search);
  const targetRoute = ROUTES[params.get('p') as ROUTE_IDS];

  // Only Trigger on path of veranstaltungen
  if (window.location.pathname === '/veranstaltungen') {
    return targetRoute || defaultRoute;
  }

  return null;
};

@Component({
  selector: 'iko-va-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: [
    './wrapper.component.css',
    './../../../../node_modules/@solid-design-system/components/dist/versioned-styles/solid-styles.css',
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class WrapperComponent implements OnInit, OnDestroy {
  routes = ROUTES;
  route: Route | null = null;
  authState: AuthState = AuthState.UNAUTHENTICATED;

  loading = true;

  constructor(
    private authService: AuthService,
    private routingService: RoutingService
  ) {}

  ngOnInit() {
    this.routingService.registerRouteChangeListener(() => {
      this.route = getCurrentRoute();
    });
    this.route = getCurrentRoute();

    this.checkAuth();
  }

  ngOnDestroy(): void {
    this.routingService.unregisterRouteChangeListener();
    window.removeEventListener('keypress', this.clearCacheOnHardReload);
  }

  async checkAuth() {
    const isAuthenticated = await this.authService.isAuthenticated();

    if (isAuthenticated) {
      this.authState = AuthState.AUTHENTICATED;
      this.registerKeybindings();
      this.loading = false;
    } else {
      this.authState = AuthState.UNAUTHENTICATED;
      await this.authService.login();
    }
  }

  registerKeybindings() {
    window.addEventListener('keydown', this.clearCacheOnHardReload);
  }

  /**
   * Clear local storage on hard reload (CRTL + F5)
   * @param e keyboard event provided by browser api
   */
  clearCacheOnHardReload = (e: KeyboardEvent) => {
    if (e.ctrlKey) console.warn('HI');
    if (e.key === 'F5') console.warn('HI');
    if (e.ctrlKey && e.key === 'F5') {
      CacheService.removeEvents(true);
      CacheService.removeEvents(false);
      this.reloadPage();
    }
  };

  /**
   * Reloads page.
   * @important This function is required for preventing reloads in unit tests
   */
  reloadPage = () => {
    window.location.reload();
  };

  login = ()=>{}
}
