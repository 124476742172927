<iko-va-grid type="container">
  <iko-va-grid
    type="item"
    *ngFor="let participantStatus of events; index as i"
    [span]="showFirstElement && i === 0 ? ['2xl-6', 'xl-6'] : []"
  >
    <iko-va-event-card
      [participantStatus]="participantStatus"
      [isFirstElement]="i === 0 && showFirstElement"
    >
    </iko-va-event-card>
  </iko-va-grid>
</iko-va-grid>

<iko-va-container>
  <iko-va-error-message *ngIf="events.length === 0" [errorName]="'Keine Treffer.'" [errorMessage]="'Es wurden keine Veranstaltungen für den aktuellen Filter gefunden.'"></iko-va-error-message>
</iko-va-container>
