<p class="sd-2-11-0-headline sd-2-11-0-headline--size-3xl">Agenda</p>
<div>
  <table
    cksort="table_nosorting"
    cktype="table_coloured"
    class="table table--colored sd-2-11-0-table"
    style="width: 100%; visibility: visible"
  ><tbody>
      <tr *ngFor="let session of sessions; index as i; first as isFirst">
        <td class="left-col">
          <strong>{{ getStartTime(session)}}</strong><br/>
          {{ getStartDate(session)}}
        </td>
        <td class="center-col">
          <strong>{{ session.msevtmgt_name }}</strong>
          <ng-template
            ngFor
            let-speaker
            [ngForOf]="
              session.msevtmgt_msevtmgt_session_msevtmgt_speakerengagement_Session
            "
          >
            <br />{{ getSpeakerNameAndTitle(speaker?.msevtmgt_Speaker) }}
          </ng-template>
        </td>
        <td class="right-col">
          <div class="image-stack">
            <ng-template ngFor let-speaker [ngForOf]="session.msevtmgt_msevtmgt_session_msevtmgt_speakerengagement_Session">
              <div class="portrait-container"><img class="portrait-img" [src]="getSpeakerImage(speaker)" /></div>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
